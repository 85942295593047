/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
/* eslint-disable no-param-reassign */
import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
    format, compareAsc, addDays, subDays, startOfWeek, getWeek, startOfMonth, endOfMonth, getYear, isLastDayOfMonth, isFirstDayOfMonth, isSameMonth, getMonth,
} from 'date-fns';
import ReactHTMLParser from 'react-html-parser';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
    object, bool, func, string, shape, arrayOf, number, array,
} from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import mbpLogger from 'mbp-logger';
import { parse } from 'qs';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import availablityDeliveryCalendar from '../../../../../../apis/cart-service-apis/availablityDeliveryCalendar';
import { actions as tagManagerActions } from '../../../../../../state/ducks/TagManager';

import {
    getLocationType,
    getValidatedZipcode,
    getUserSubmittedProductFilterZipcode,
    getSubscriptionFrequencyData,
    getSSRDeviceType,
} from '../../../../../../state/ducks/App/App-Selectors';
import { getActiveABTests } from '../../../../../../state/ducks/App/ducks/ABTesting/ABTesting-Selectors';

import UnavailableDateModal from './Partials/UnavailableDateModal';
import {  getPresentationFamily } from '../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { checkDateHasBackUpProduct } from '../../../../../helpers/checkFptGptProductBackUp';
import determineIfPastCutoffTime from '../../../../../helpers/determineCutoffTime';

import DesktopMobile from '../../../../../helpers/DesktopMobile/DesktopMobile';
import CalendarErrorDialog from './Partials/CalendarErrorDialog';

import { errorActions } from '../../../../../../state/ducks/Common/ducks/Error';
import { updateOrder } from '../../../../../../state/ducks/Cart/ducks/UpdateCart/UpdateCart-Actions';

import {
    getIsPassportUserLoggedIn,
    getIsAuthenticatedStatus,
} from '../../../../../../state/ducks/Member/ducks/Common/Common-Selectors';

import {
    getCartError,
    getLoaderStatus,
    isCheckoutPage,
    getPmallSummaryInfo,
} from '../../../../../../state/ducks/Common/Common-Selectors';
import { addToCartNewCartServices, clearAddToCartError } from '../../../../../../state/ducks/AddToCart/AddToCart-Actions';
import { getAddToCartError, getAddToCartLoaderStatus } from '../../../../../../state/ducks/AddToCart/AddToCart-Selectors';
import { getPassportSubscriptionStatus } from '../../../../../../state/ducks/Passport/Passport-Selectors';

import noop from '../../../../../helpers/noop';
import browserAgnosticNewDate from '../../../../../helpers/browserAgnosticDate/browserAgnosticDate';
import { withAuth } from '../../../../../../state/ducks/Member/ducks/Auth/Plugin/auth';
import CalendarLoadingSpinner from './Partials/CalendarLoadingSpinner';
import CalendarBanner from './Partials/CalendarBanner';
import FlexibleShippingOptions from './Partials/FlexibleShippingOptions';
import MonthTitles from './Partials/MonthTitles';
import ShippingMessage from './Partials/ShippingMessage';
import UnavailableDateBox from './Partials/UnavailableDateBox';
import CalendarModalHeader from './Partials/CalendarModalHeader';
import {
    MMMM_MONTH_NAMES,
    z2fmt,
    formatDateYYYY_MM_DD,
    yyyy_mm_ddToDate,
    getNow,
    firstOfNextMonth,
    firstofPrevMonth,
    formatDate_d_MMM_yy,
} from './Partials/Helper/helper';
import CalendarModalBottomDiv from './Partials/CalendarModalBottomDiv';
import CalendarModalTransition from './Partials/CalendarModalTransition';
import AltCalendarWidget from './Partials/AltCalendarWidget';
import AddToCartBuilderObject from '../GraphqlFoodAddToCart/Partials/AddToCartBuilderObject';

const styles = (theme) => ({
    closeModal: {
        position: 'absolute',
        fontWeight: 'normal',
        right: '12px',
        color: theme.palette.colorNeutral70,
        cursor: 'pointer',
    },
    modelHeaderV2: {
        height: 'auto!important',
    },
    modalHeaderContainer: {
        '& .modalHeader': {
            padding: '15px 0px',
            // [theme.breakpoints.down('sm')]: {
            //     padding: '3px 0px',
            // },
        },
    },
    modalHeader: {
        borderBottom: `2px solid ${theme.palette.cms?.primary || theme.palette.colorPrimary}`,
        textTransform: 'uppercase',
        color: '#52297E',
        fontSize: '16px',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    sameDayDeliveryBanner: {
        fontFamily: 'Helvetica Neue',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '20px',
        textAlign: 'center',
        height: '50px',
        // top: '50px',
        backgroundColor: '#F5F5F5',
    },
    modalHeaderV3: {
        textTransform: 'uppercase',
        color: '#52297E',
        fontSize: '20px',
        fontWeight: 500,
        textAlign: 'center',
        fontFamily: 'Poppins',
        lineHeight: '30px',
        letterSpacing: '0.01em',
        height: '50px !important',
        background: '#F5F5F5',
        [theme.breakpoints.down('sm')]: {
            padding: '3px 0px !important',
        },
        [theme.breakpoints.down(650)]: {
            background: '#FAFAFA',
            height: '32px !important',
            fontSize: '14px',
            lineHeight: '24px',
        },
    },
    modalContainer: {
        [theme.breakpoints.up('sm')]: {
            top: '10% !important',
        },
        '& div': {
            alignItems: 'start',
            '& div': {
                maxHeight: '100%',
                height: '100%',
                width: '100%',
                margin: '0px',
                alignItems: 'start',
                borderRadius: '0px',
                maxWidth: '670px',
                position: 'relative',
                '& .todayTomDateNotAvailText': {
                    [theme.breakpoints.down('sm')]: {
                        height: 'auto',
                    },
                },
                [theme.breakpoints.up('sm')]: {
                    height: 'auto',
                },
            },
        },
    },
    modalContainerV2: {
        [theme.breakpoints.up('sm')]: {
            top: '5% !important',
        },
        '& div': {
            alignItems: 'start',
            '&.MuiDrawer-paperAnchorBottom': { // paperAnchorBottom is the css for the mobile slideup drawer
                overflowX: 'hidden',
                overflowY: 'auto',
                '-webkit-tap-highlight-color': 'transparent',
            },
            [theme.breakpoints.down('sm')]: {
                '& .MuiDialog-paper': {
                    overflow: 'hidden',
                },
            },
            '& div': {
                maxHeight: '100%',
                height: '100%',
                width: '100%',
                margin: '0px',
                alignItems: 'start',
                borderRadius: '0px',
                maxWidth: '905px',
                position: 'relative',
                [theme.breakpoints.up('sm')]: {
                    height: 'auto',
                },
            },
        },
    },
    monthTitle: {
        textAlign: 'center',
        fontSize: '16px',
        fontWeight: '700',
        textTransform: 'uppercase',
    },
    monthTitleV2: {
        textAlign: 'center',
        fontSize: '18px',
        fontWeight: '500',
        right: '10px',
        padding: '14px 0px 16px 0px',
        left: '-1',
        fontFamily: 'Poppins',
    },
    daysRow: {
        display: 'flex !important',
    },
    weekDay: {
        display: 'inline-block',
        textAlign: 'center',
        width: '62px',
        margin: '0px 1%',
        fontSize: '14px',
        fontWeight: '400',
        [theme.breakpoints.down(1025)]: {
            padding: '6px 0',
        },
        [theme.breakpoints.down(550)]: {
            width: '11.6%',
            padding: '6px 0',
        },
        [theme.breakpoints.between(392, 394)]: { // this breakpoint is to target iphone 14 pro specifically
            width: '12.2%',
        },
    },
    weekDayV2: {
        display: 'inline-block',
        textAlign: 'center',
        width: '62px',
        margin: '0px 1%',
        fontSize: '14px',
        fontWeight: '400',
        [theme.breakpoints.down(1025)]: {
            padding: '6px 0',
        },
        [theme.breakpoints.down(886)]: {
            width: '12.2%',
            padding: '6px 0',
        },
        [theme.breakpoints.down(650)]: {
            width: '11.3%',
            padding: '6px 0',
        },
        [theme.breakpoints.down(431)]: {
            width: '11.6%',
        },
        [theme.breakpoints.down(390)]: {
            width: '11.3%',
        },
        [theme.breakpoints.between(392, 394)]: { // this breakpoint is to target iphone 14 pro specifically
            width: '12.2%',
        },
        [theme.breakpoints.down(321)]: {
            width: '12.2%',
        },
    },
    dateIcon: {
        position: 'absolute',
        right: 0,
        top: 0,
        height: '20px',
        width: '16px',

        '&-unavailable': {
            opacity: 0.4,
            filter: 'grayscale(1)',
        },

        '&-hiddenOnHover': {
            opacity: 0,
        },

        [theme.breakpoints.down(480)]: {
            height: '15px',
            width: '11px',
        },
    },
    weekDayName: {
        display: 'flex !important',
        flexWrap: 'wrap',
    },
    dateAvailable: {
        background: theme.palette.colorPrimaryContrast,
        cursor: 'pointer',
        border: `1px solid  ${theme.palette.grey10}`,
        width: '62px',
        display: 'flex',
        textAlign: 'center',
        margin: '1%',
        color: theme.palette.cms?.primary || theme.palette.colorPrimary,
        fontSize: '14px',
        fontWeight: 'bold',
        padding: '8px 0px',
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
        lineHeight: '16px',
        height: '46px',
        flexDirection: 'column',
        '&:hover': {
            fontWeight: '600',
        },
        [theme.breakpoints.down(1025)]: {
            height: '40px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            fontWeight: 'bold',
            padding: '6px 0',
            lineHeight: '14px',
        },
        [theme.breakpoints.down(550)]: {
            width: '11.6%',
            height: '37px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            fontWeight: 'bold',
            padding: '6px 0',
            lineHeight: '14px',
        },
    },
    dateAvailableV2: {
        background: theme.palette.colorPrimaryContrast,
        cursor: 'pointer',
        border: '1px solid #E9E9E9',
        width: '64px',
        display: 'flex',
        textAlign: 'center',
        margin: '4px',
        color: theme.palette.cms?.primary || theme.palette.colorPrimary,
        fontSize: '14px',
        fontWeight: 'bold',
        padding: '8px 0px',
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
        lineHeight: '16px',
        height: '44px',
        flexDirection: 'column',
        '&:hover': {
            fontWeight: '600',
            [theme.breakpoints.up(1025)]: {
                background: '#18799B !important',
                color: 'white !important',
            },
        },
        [theme.breakpoints.down(1025)]: {
            height: '40px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            fontWeight: 'bold',
            padding: '6px 0',
            lineHeight: '14px',
        },
        [theme.breakpoints.down(650)]: {
            width: '12%',
        },
        [theme.breakpoints.down(431)]: {
            width: '56px',
            height: '37px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            fontWeight: 'bold',
            padding: '6px 0',
            lineHeight: '14px',
            border: 'none !important',
            margin: '4px 0px',
        },
        [theme.breakpoints.down(391)]: {
            width: '53px',
        },
        [theme.breakpoints.down(376)]: {
            width: '50px',
        },
        [theme.breakpoints.down(361)]: {
            width: '48px',
        },
        [theme.breakpoints.down(345)]: {
            width: '47px',
        },
    },
    renderSurchargeWithoutHover: {
        minHeight: 40,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        fontWeight: 'bold',
        padding: '6px 0',
        lineHeight: '14px',
    },
    dateSurcharge: {
        color: '#2F7BA6',
        '@media (hover: hover)': {
            '&:hover': {
                fontSize: '0.75rem',
                fontWeight: '600',
                [theme.breakpoints.down(560)]: {
                    fontSize: '0.55rem',
                },
            },
        },
    },
    dateSurchargeV2: {
        '@media (hover: hover)': {
            '&:hover': {
                fontSize: '0.75rem',
                fontWeight: '600',
                background: '#18799B !important',
                color: 'white !important',
                [theme.breakpoints.down(560)]: {
                    fontSize: '0.55rem',
                },
            },
        },
    },
    surchargeV2: {
        fontSize: '8px',
        margin: '4px auto 0 auto',
        lineHeight: '8px',
        fontWeight: 400,
        '@media(min-width: 600px)': {
            fontSize: '10px',
        },
        [theme.breakpoints.up(1025)]: {
            fontSize: '12px',

        },
    },
    surcharge: {
        color: '#2F7BA6',
        fontSize: '8px',
        margin: '4px auto 0 auto',
        lineHeight: '8px',
    },
    noDateAvailableRedesign: {
        height: '46px',
        width: '62px',
        display: 'inline-block',
        textAlign: 'center',
        color: '#9e9e9e',
        margin: '1%',
        fontSize: '14px',
        fontWeight: '500',
        position: 'relative',
        padding: '8px 0px',
        '& notVisible': {
            visibility: 'hidden',
        },
        [theme.breakpoints.down(1025)]: {
            height: '40px',
            // width: '45px',
        },
        [theme.breakpoints.down(550)]: {
            height: '37px',
            width: '11.6% ',
        },
    },
    noDateAvailableRedesignV2: {
        height: '44px',
        width: '64px',
        display: 'inline-block',
        textAlign: 'center',
        color: '#9e9e9e',
        margin: '4px',
        fontSize: '14px',
        fontWeight: '500',
        position: 'relative',
        padding: '8px 0px',
        '& notVisible': {
            visibility: 'hidden',
        },
        [theme.breakpoints.down(1025)]: {
            height: '40px',
            // width: '45px',
        },
        [theme.breakpoints.down(650)]: {
            width: '12%',
        },
        [theme.breakpoints.down(431)]: {
            height: '37px',
            width: '56px',
            margin: '4px 0px',
        },
        [theme.breakpoints.down(391)]: {
            width: '53px',
        },
        [theme.breakpoints.down(376)]: {
            width: '50px',
        },
        [theme.breakpoints.down(361)]: {
            width: '48px',
        },
        [theme.breakpoints.down(345)]: {
            width: '47px',
        },
    },
    prevBtn: {
        position: 'absolute',
        left: '15px',
        color: '#52297E',
        fontSize: '14px',
        fontWeight: '700',
        cursor: 'pointer',
        '&::before': {
            content: '"<"',
            paddingRight: '7px',
        },
    },
    prevArrowBtn: {
        position: 'absolute',
        left: '30px !important',
        color: '#000000 !important',
        fontSize: '14px',
        cursor: 'pointer',
    },
    nextBtn: {
        position: 'absolute',
        right: '15px',
        color: '#52297E',
        fontSize: '14px',
        fontWeight: '700',
        cursor: 'pointer',
        marginRight: '20px',
        '&::after': {
            content: '">"',
            paddingLeft: '8px',
        },
        [theme.breakpoints.down(600)]: {
            marginRight: '10px',
        },
    },
    nextArrowBtn: {
        position: 'absolute',
        right: '1px',
        color: '#000000 !important',
        fontSize: '14px',
        cursor: 'pointer',
        [theme.breakpoints.down(600)]: {
            marginRight: '25px',
        },
    },
    mobBottomDivMobile: {
        margin: '0px 0px 15px !important',
        [theme.breakpoints.up('sm')]: {
            display: 'none !important',
        },
    },
    mobBottomDiv: {
        margin: '0px 0px 15px !important',
        width: '100%',
    },
    mobBottomRight: {
        display: 'inline-block',
        '& img': {
            width: 'auto',
            padding: '15px 0px',
        },
        [theme.breakpoints.down(600)]: {
            width: '45%',
        },
    },
    mobBottomLeft: {
        display: 'inline-block',
        verticalAlign: 'top',
        [theme.breakpoints.down(600)]: {
            width: '55%',
        },
    },
    leftCalendar: {
        [theme.breakpoints.up(960)]: {
            width: '66% !important',
            float: 'left',
            margin: '20px 0px 20px 5px !important',
        },
        [theme.breakpoints.up(600)]: {
            // width: '66% !important',
            float: 'left',
            margin: '20px 0px 20px 5px !important',
        },
        '&::before': {
            width: '100%',
            height: '100%',
            content: '""',
        },
    },
    calendarMobileLayout: {
        [theme.breakpoints.up('sm')]: {
            padding: '0px 40px',
            margin: '0px 0px 15px 5px !important',
        },
    },
    calendarBoxV2: {
        paddingBottom: '0!important',
        height: 'auto!important',
    },
    dialogTitle: {
        '& .rightCalendar': {
            [theme.breakpoints.up('sm')]: {
                width: '30%',
                margin: '8px',
                borderRadius: '5px',
                padding: '4px',
                marginTop: '30px',
            },
        },
        '& .monthTitle': {
            margin: '14px 0px 16px 0',

        },
        '& .mobBottomRight': {
            width: '47%',
            [theme.breakpoints.up(600)]: {
                width: '29%',
                marginLeft: '75px',
            },
        },
        '& .mobBottomLeft': {
            top: '15px',
            width: '53%',
        },
        '& .mobBottomLeftV2': {
            [theme.breakpoints.up(600)]: {
                width: '65%',
            },
        },
        '& .mobBottomRightV2': {
            float: 'right',
            marginRight: '35px',
            maxWidth: '156px',
            marginLeft: '0px !important',
            padding: '15px',
            textAlign: 'center',
            top: '15px',
            [theme.breakpoints.down(445)]: {
                marginRight: '0px',
            },
        },
    },
    loadingFadeOut: {
        '&::before': {
            width: '100%',
            height: '100%',
            content: '""',
            zIndex: '100',
            backgroundColor: 'rgba(255,255,255,0.5)',
            position: 'absolute',
        },
    },
    maxClosedWidth: {
        '& div': {
            '& div': {
                maxWidth: '300px !important',
                borderRadius: 2,
                paddingBottom: 10,
            },
        },
    },
    todayTomDateNotAvailContainer: {
        width: '100%',
        background: '#f4f2f7',
        '& p': {
            padding: '10px',
            margin: 0,
            textAlign: 'center',
            color: '#f71449',
            fontSize: '15px',
        },
    },
    negativeSurchargeMessage: {
        width: '100%',
        '& > p': {
            display: 'flex',
            margin: '10px 0',
            color: '#1F1F1F',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '18px',
            '& > span': {
                marginTop: '0px',
                marginBottom: '0px',
            },
            [theme.breakpoints.down(395)]: {
                fontSize: '13px',
            },
            [theme.breakpoints.down(376)]: {
                fontSize: '12px',
            },
        },
    },
    negativeSurchargeMessageV2: {
        margin: '0',
    },
    negativeSurchargeIcon: {
        marginLeft: '3px',
        display: 'flex',
        '& > img': {
            maxWidth: '100%',
        },
    },
    negativeSurchargeColor: {
        // color: '#52297E',
    },
    hoverSurcharge: {
        '@media (hover: hover)': {
            '&:hover': {
                '& p': {
                    display: 'none',
                },
            },
        },
    },
    hoverSurchargeV2: {
        '&:hover': {
            '& p': {
                color: 'white',
                fontWeight: 'bold',
                fontSize: '15px',
            },
        },
    },
    divider: {
        minHeight: '1px',
        background: '#BFBFBF',
        height: 'auto!important',
        [theme.breakpoints.down('xs')]: {
            marginTop: '25px!important',
        },
    },
    bottomContent: {
        padding: '0 44px',
        height: 'auto!important',
        [theme.breakpoints.down('xs')]: {
            padding: '0 28px',
        },
    },
    surchargeMessageV2: {
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: 14,
        lineHeight: '20px',
        color: '#52297E',
        paddingBottom: '20px',
    },
    calendarViewButtons: {
        width: '420px !important',
        height: '63px !important',
        margin: '5px 0px 0px 40px !important',
        float: 'left',
        [theme.breakpoints.down(650)]: {
            margin: '0px 0px 0px 0px !important',
            width: '100% !important',
            display: 'flex',
            justifyContent: 'center',
        },
        [theme.breakpoints.down(431)]: {
            margin: '0px 0px 0px 0px !important',
            width: '430px !important',
            boxShadow: '0px 2px 4px 0px #2F2F2F26',
            padding: '5px 0px 5px 0px',
        },
        [theme.breakpoints.down(361)]: {
            right: '15px',
        },
    },
    rangeButtonDisabled: {
        border: 'none',
        width: '131px !important',
        borderRadius: '60px !important',
        padding: '12px',
        color: '#000000',
        backgroundColor: '#E2F4F5',
        fontFamily: 'Poppins',
        fontSize: '18px',
        fontWeight: 400,
        textAlign: 'center',
        verticalAlign: 'middle',
        cursor: 'pointer',
        marginRight: '5px',
        marginTop: '5px',
        [theme.breakpoints.down(650)]: {
            fontSize: '14px',
            width: '98px !important',
            height: '36px !important',
            lineHeight: '18px',
            padding: '0px',
            marginTop: '10px !important',
        },
    },
    rangeButtonEnabled: {
        border: 'none',
        width: '131px !important',
        borderRadius: '60px !important',
        padding: '12px',
        color: '#000000',
        backgroundColor: '#BFE2EA',
        fontFamily: 'Poppins',
        fontSize: '18px',
        fontWeight: 700,
        textAlign: 'center',
        verticalAlign: 'middle',
        cursor: 'pointer',
        marginTop: '5px',
        [theme.breakpoints.down(650)]: {
            fontSize: '14px',
            width: '103px !important',
            height: '36px !important',
            lineHeight: '18px',
            padding: '0px',
            marginTop: '10px !important',
            marginRight: '5px',
        },
        '&:focus': {
            outline: 'none',
        },
    },
    exactButtonEnabled: {
        border: 'none',
        width: '123px !important',
        borderRadius: '60px !important',
        padding: '12px',
        fontFamily: 'Poppins',
        fontSize: '18px',
        fontWeight: 700,
        textAlign: 'center',
        verticalAlign: 'middle',
        color: '#000000',
        backgroundColor: '#BFE2EA',
        cursor: 'pointer',
        float: 'right',
        marginTop: '5px',
        [theme.breakpoints.down(650)]: {
            fontSize: '14px',
            width: '97px !important',
            height: '36px !important',
            marginRight: '60px',
            padding: '0px',
            marginTop: '10px !important',
        },
        [theme.breakpoints.down(431)]: {
            marginRight: '70px',
        },
        [theme.breakpoints.down(345)]: {
            marginRight: '100px',
        },
        '&:focus': {
            outline: 'none',
        },
    },
    exactButtonDisabled: {
        border: 'none',
        width: '121px !important',
        borderRadius: '60px !important',
        padding: '12px',
        fontFamily: 'Poppins',
        fontSize: '18px',
        fontWeight: 400,
        textAlign: 'center',
        verticalAlign: 'middle',
        color: '#000000',
        backgroundColor: '#E2F4F5',
        cursor: 'pointer',
        float: 'right',
        marginTop: '5px',
        [theme.breakpoints.down(650)]: {
            fontSize: '14px',
            width: '97px !important',
            height: '36px !important',
            lineHeight: '18px',
            marginRight: '65px',
            padding: '0px',
            marginTop: '10px !important',
        },
        [theme.breakpoints.down(431)]: {
            marginRight: '70px',
        },
        [theme.breakpoints.down(345)]: {
            marginRight: '100px',
        },
    },
    applyDateButton: {
        border: '1px solid #E80A4D',
        height: '56px !important',
        width: '100% !important',
        margin: '30px -10px !important',
        borderRadius: '3px !important',
        color: 'white',
        backgroundColor: '#E80A4D',
        fontFamily: 'Poppins',
        fontSize: '18px',
        fontWeight: 700,
        textAlign: 'center',
        verticalAlign: 'middle',
        letterSpacing: '0.01em',
        lineHeight: '26px',
        padding: '15px 28px',
        gap: '12px',
        cursor: 'pointer',
        position: 'absolute',
        [theme.breakpoints.down(650)]: {
            position: 'relative',
            margin: '10px 0px 20px 20px !important',
            width: '90% !important',
        },
    },
    deliveryMessageContainer: {
        height: '152px !important',
        width: '32% !important',
        float: 'right',
        margin: '30px 39px 30px 0px !important',
        padding: '20px 10px 0px',
        fontFamily: 'Poppins',
        fontSize: '14px',
        color: '#000000',
        lineHeight: '24px',
        top: '50px',
        borderRadius: '3px !important',
        backgroundColor: '#E2F4F5',
        [theme.breakpoints.down(901)]: {
            width: '35% !important',
            margin: '30px 20px 30px 0px !important',
        },
        [theme.breakpoints.down(842)]: {
            width: '38% !important',
            fontSize: '13px',
        },
        [theme.breakpoints.down(709)]: {
            width: '42% !important',
        },
        [theme.breakpoints.down(650)]: {
            top: '-20px',
            height: 'auto !important',
            background: 'none !important',
            width: '90% !important',
            padding: '10px 0px',
            margin: '10px 20px -10px 0px !important',
            borderTop: '1px solid #C4C4C4',
            borderBottom: '1px solid #C4C4C4',
            fontSize: '14px',
        },
    },
    deliveryMessageTitle: {
        margin: '0px',
        fontWeight: 700,
    },
    deliveryMessageText: {
        margin: '0px',
        fontWeight: 400,
    },
    deliveryOptionsText: {
        color: '#000000',
        width: '158px !important',
        height: '63px !important',
        float: 'left',
        textAlign: 'center',
        padding: '20px 8px 20px 0px',
        fontFamily: 'Poppins',
        fontSize: '18px',
        fontWeight: 700,
        lineHeight: '18px',
        marginRight: '5px',
        [theme.breakpoints.down(650)]: {
            fontSize: '14px',
            textAlign: 'right',
            paddingRight: '10px !important',
        },
        [theme.breakpoints.down(345)]: {
            width: '130px !important',
            paddingRight: '5px !important',
        },
    },
    newCalendarContainer: {
        width: '57.6% !important',
        margin: '6px 0px 20px 40px !important',
        float: 'left',
        [theme.breakpoints.down(901)]: {
            width: '57% !important',
        },
        [theme.breakpoints.down(854)]: {
            margin: '25px 0px 25px 10px !important',
        },
        [theme.breakpoints.down(709)]: {
            width: '53% !important',
        },
        [theme.breakpoints.down(650)]: {
            height: 'auto !important',
            width: '100% !important',
            margin: '25px 0px 25px 10px !important',
            zIndex: 1,
        },
        [theme.breakpoints.between(429, 431)]: { // this breakpoint is to target iphone 14 pro max specifically
            width: '96% !important',
        },
        [theme.breakpoints.between(392, 394)]: { // this breakpoint is to target iphone 14 pro specifically
            width: '96% !important',
        },
        [theme.breakpoints.down(376)]: {
            height: 'auto !important',
            width: '100% !important',
            margin: '0px 0px 25px 10px !important',
            zIndex: 1,
        },
        [theme.breakpoints.down(321)]: {
            margin: '0px 0px 25px 0px !important',
        },
    },
    arrivalDetails: {
        height: '43px',
        width: '255px',
        marginBottom: '16px !important',
        textAlign: 'center',
        [theme.breakpoints.down(650)]: {
            display: 'flex !important',
            justifyContent: 'center',
            marginBottom: '5px !important',
        },
    },
    deliveryDetails: {
        height: '43px',
        width: '255px',
        textAlign: 'center',
        [theme.breakpoints.down(650)]: {
            display: 'flex !important',
            justifyContent: 'center',
        },
    },
    spinnerContainer: {
        position: 'absolute !important',
        height: 'auto !important',
        top: '50%',
    },
    lineBreak: {
        width: '100%',
        borderTop: '1px solid #dbdbdb',
        borderLeft: 'none',
        borderBottom: 'none',
    },
});

let initialExactDate;
let initialRangeDate;
let rangeDatesAvailable;
let addOnsDateObj;
let addOnsDeliveryDatetype;
export class CalendarModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            prevBtnCheck: false,
            surcharge: 'Standard Service Charge',
            now: '',
            end: '',
            spinnerActive: false,
            calendarIsLoading: false,
            focusedDate: null,
            flex: {
                flexActivated: false,
                flexData: {},
                selectedDate: null,
                dateFlexImage: {},
                showFlexPopup: '',
            },
            flexTaggingData: {},
            dateIcons: {},
            altCalendar: {
                altCalendarActivated: false,
                altCalendarData: null,
            },
            clickableUnavailableDates: {
                unavailableDateModalActive: false,
                clickableDates: null,
                fakeDateInfo: {},
            },
            rangeButtonClicked: false,
            exactButtonClicked: false,
            dateObj: {},
            formattedSelectedDate: '',
            guaranteedCharge: '',
        };
        // The following object is instantiated outside of state so that assigment is syncronous.
        this.apiDateObj = {};
        this.negativeSurchargeAmount = null;
        this.negativeSurchargeDates = [];
        this.lockNegativeSurcharge = false;
        this.dataObject = null;
    }

    componentDidMount() {
        const {
            location, trackEvent, featureFlags, deliveryType, ldFlag, brandId,
        } = this.props;
        localStorage.removeItem('totalSurcharge');
        initialExactDate = '';
        initialRangeDate = '';
        this.setState({
            dateObj: {},
        });
        if (deliveryType === 'GPT') {
            this.setState({
                surcharge: 'Standard Shipping Charge',
            });
        }
        if (location.pathname.includes('add-ons') && brandId === '1012' && !featureFlags['is-new-delivery-calendar-enabled'] && featureFlags['is-new-delivery-calendar-checkout-enabled']) {
            if (addOnsDeliveryDatetype === 'on') {
                this.setState({
                    exactButtonClicked: true,
                    rangeButtonClicked: false,
                    formattedSelectedDate: addOnsDateObj?.data?.deliveryDate,
                    dateObj: addOnsDateObj,
                });
            }
            if (addOnsDeliveryDatetype !== 'on' && addOnsDateObj?.data?.calendarDateType === 'between') {
                this.setState({
                    rangeButtonClicked: true,
                    exactButtonClicked: false,
                    dateObj: addOnsDateObj,
                });
            }
        }

        if (location.pathname.includes('checkout') || location.pathname.includes('add-ons')) {
            this.getCalendarFromAPI();
        }
        const experimentCalendarFlag = ldFlag?.[`experimentCalendarFirstWeedaySunday${deliveryType === 'FPT' ? 'Fpt' : 'Gpt'}`];
        const eventAction = featureFlags[`is-floral-calendar-first-day-of-week-sunday-${deliveryType?.toLowerCase?.()}`] && 'Calendar Week Variant';
        trackEvent({
            eventCategory: 'Test Impression',
            eventAction,
        });
        if (featureFlags[`is-floral-calendar-first-day-of-week-sunday-${deliveryType?.toLowerCase?.()}`] && experimentCalendarFlag?.toLowerCase() !== 'control') {
            trackEvent({
                eventCategory: 'Test Impression',
                eventAction: 'Calendar week day start',
                eventLabel: `Variant-${deliveryType}`,
            });
        } else {
            trackEvent({
                eventCategory: 'Test Impression',
                eventAction: 'Calendar week day start',
                eventLabel: `Control-${deliveryType}`,
            });
        }
    }

    componentDidUpdate(prevProps) {
        const { calendarIsLoading } = this.state;
        const {
            isOpen, cmsData, featureFlags, selectedSku, activeABTests, zipCode, userSubmittedZip, isQuickviewOrigin, partNumber, deliveryType,
        } = this.props;

        if (
            ((isOpen === true
            && isOpen !== prevProps.isOpen) || (isOpen && cmsData && !prevProps.cmsData))
            && calendarIsLoading === false
        ) {
            this.getCalendarFromAPI();
            this.apiDateObj = {};
            this.negativeSurchargeAmount = null;
            this.negativeSurchargeDates = [];
            this.lockNegativeSurcharge = false;
            this.dataObject = null;
        }
        if (!prevProps.cmsData && cmsData) {
            const dateIcons = {};
            const calenderDateIcon = cmsData?.entries?.[0]?.calendar_date_icons;
            const dateIconArray = calenderDateIcon?.[0]?.date && calenderDateIcon;
            if (!(selectedSku?.sympathyValue === '2' || selectedSku?.sympathyValue === '1') && dateIconArray && dateIconArray.length > 0) {
                dateIconArray.forEach((dateInfo) => {
                    dateIcons[dateInfo.date] = dateInfo;
                });
            }
            if (Object.keys(dateIcons).length > 0) {
                this.setState({
                    dateIcons,
                });
            }
            const altCalendar = cmsData?.entries?.[0]?.alt_calendar;
            if (featureFlags['is-alt-calendar-flow-enabled'] && altCalendar?.alt_calendar_start_date && altCalendar?.alt_calendar_end_date) {
                // ContentStack gives dates in the format of yyyy-mm-dd which is parsed in an inconsistent
                // way by JS.
                // need to convert the days to a format that is consistently parsed
                // yyyy/mm/dd
                const altCalendarStateVersion = { ...altCalendar };
                altCalendarStateVersion.alt_calendar_start_date = altCalendarStateVersion.alt_calendar_start_date.split('-').join('/');
                altCalendarStateVersion.alt_calendar_end_date = altCalendarStateVersion.alt_calendar_end_date.split('-').join('/');
                if (compareAsc(new Date(altCalendarStateVersion.alt_calendar_start_date), new Date(altCalendarStateVersion.alt_calendar_end_date)) < 1) {
                    // check to make sure the end day is the same day or later than the start day to prevent getting stuck in an infinite loop
                    this.setState({
                        altCalendar: {
                            altCalendarActivated: false,
                            altCalendarData: altCalendarStateVersion,
                        },
                    });
                }
            }

            const fakeFlexFlagIsActive = featureFlags['is-unavailable-calendar-dates-clickable'] && !featureFlags['is-new-flex-flows-enabled'];
            const fakeFlexABTestIsActive = (activeABTests.force_flex_enabled_fpt === 'VARIANT' && deliveryType === 'FPT') || (activeABTests.force_flex_enabled_gpt === 'VARIANT' && deliveryType === 'GPT');
            if (fakeFlexFlagIsActive && fakeFlexABTestIsActive) {
                const clickableUnavailableDates = cmsData?.entries?.[0]?.clickable_unavailable_dates;
                const isZipcodeRestrictionEnabled = clickableUnavailableDates?.is_zipcode_restriction_enabled;
                let restrictedZipcodes = null;
                let reconciledZipcode = userSubmittedZip;
                if (isQuickviewOrigin) reconciledZipcode = zipCode;
                if (isZipcodeRestrictionEnabled) {
                    // if this is active only show this experience in the specified zipcodes
                    restrictedZipcodes = clickableUnavailableDates.zipcodes_to_include.reduce((acc, zip) => ({ ...acc, [zip]: true }), {});
                }
                const isBasecodeExclusionActive = clickableUnavailableDates?.is_basecode_exclusion_active;
                let excludedBasecodes = null;
                if (isBasecodeExclusionActive) {
                    // if this is active, do not show this experience for specified basecodes
                    excludedBasecodes = clickableUnavailableDates.basecodes_to_exclude.reduce((acc, code) => ({ ...acc, [code]: true }), {});
                }
                if (clickableUnavailableDates?.dates_to_display?.length
                    && !(isZipcodeRestrictionEnabled && restrictedZipcodes && !restrictedZipcodes[reconciledZipcode])
                    && !(isBasecodeExclusionActive && excludedBasecodes && excludedBasecodes[partNumber])) {
                    const clickableDates = clickableUnavailableDates.dates_to_display.reduce((acc, dateObj) => {
                        const formattedDate = dateObj?.fake_available_date?.split('-')?.join('/');
                        return { ...acc, [formattedDate]: dateObj };
                    }, {});
                    this.setState({
                        clickableUnavailableDates: {
                            unavailableDateModalActive: false,
                            clickableDates,
                        },
                    });
                }
            }
        }
    }

    /**
     * @note This is needed due to the fact that changing redux locationType in quickview causes a page re-render.
     * @description returns locationType based on where the calendar was rendered from.
     * @returns {string} locationType
     */
    getLocationType = () => {
        const {
            isQuickviewOrigin, // indicator if calendar was rendered in quickview
            quickViewLocationType, // quickview local state
            locationType, // app context
        } = this.props;

        if (isQuickviewOrigin) {
            return quickViewLocationType;
        }

        return locationType;
    }

    getCalendarFromAPI = async (startDate = formatDateYYYY_MM_DD(getNow())) => {
        const {
            userSubmittedZip,
            selectedItemId,
            brandId,
            setCalendarDataLoadingFlag,
            handleDeliveryAvailability,
            clearCartError,
            callClearAddToCartError,
            zipCode,
            isInternationalProduct,
            passportInCart,
            isPassportUser,
            isUserLoggedIn,
            location,
            subscriptionDetails,
            subscriptionTypeAttribute,
            featureFlags,
            deliveryType,
            auth,
            flags,
            trackEvent,
        } = this.props;

        const jwt = await auth.getAccessTokenSafely();
        const today = new Date();

        let zipToSearch = userSubmittedZip;
        if (zipCode) {
            zipToSearch = zipCode;
        }
        // set zipcode as 00000 if the product is international
        zipToSearch = isInternationalProduct ? '00000' : zipToSearch;

        clearCartError({
            errType: 'cart',
            field: 'error',
        });

        callClearAddToCartError();

        // const startDate = '2020-05-01';

        // Starting at the end of the string (backwards pattern matching), continue to match any characters that aren't '-' or '_'
        const itemSku = selectedItemId.match(/[^-_]*$/);
        this.setState({ calendarIsLoading: true });

        let customerType = isUserLoggedIn ? 'R' : 'G';
        if (passportInCart || (isUserLoggedIn && isPassportUser)) {
            customerType = 'P';
        }

        let subscriptionTypeValue = null;
        if (featureFlags['is-pdp-subscription-enabled']) {
            const searchQuery = parse(location?.search?.replace(/^\?/, '') || ''); // removing ? from the search string

            subscriptionTypeValue = (searchQuery?.subscription && subscriptionDetails.subscriptionType) ? subscriptionDetails.subscriptionType : null;
            if (subscriptionTypeAttribute && !subscriptionTypeValue) {
                subscriptionTypeValue = subscriptionTypeAttribute;
            }
        }

        // allows variantID to be set with flexTestID through Launch Darkly experiment to the delivery calendar API
        // so additional copy variations can be created via CMS without code changes
        let flexTestId = null;
        if (featureFlags['is-flex-test-enabled']) {
            const flexTestImpression = (type, flexId) => (trackEvent({
                eventCategory: 'Test Impression',
                eventAction: `Experiment Flex Test ${type}`,
                eventLabel: `Flex Test ${type}: ${flexId}`,
                nonInteraction: true,
            }));
            if (flags?.whichFlexTestGpt?.variant && deliveryType === 'GPT') {
                flexTestId = flags?.whichFlexTestGpt.variant;
                flexTestImpression(deliveryType, flexTestId);
            }
            if (flags?.whichFlexTestFpt?.variant && deliveryType === 'FPT') {
                flexTestId = flags?.whichFlexTestFpt.variant;
                flexTestImpression(deliveryType, flexTestId);
            }
        }

        const locationType = this.getLocationType();

        // if it's the last day of the month and it's after the cutoff time the calendar API
        // only gives back one month of dates. However, the calendar only displays the 'next' button to browse
        // additional months if there are dates in a second month that come back from the API
        // Below checks if it's both the last day of the month and is past the cutoff time
        // if so, query the calendar for the first day of the next month which will correctly
        // return two months of data from the delivery calednar API
        // only do this if the startDate is same as today Date
        let startDeliveryDate = formatDate_d_MMM_yy(startDate);

        if (
            (startDate === formatDateYYYY_MM_DD(today))
                && isLastDayOfMonth(today)
                && (
                    (deliveryType === 'FPT' && determineIfPastCutoffTime())
                    || (deliveryType === 'GPT')
                )
        ) {
            startDeliveryDate = format(addDays(today, 1), 'd-MMM-yy');
        }

        availablityDeliveryCalendar({
            env: {},
            jwtToken: jwt,
            brandCode: brandId,
            productCode: itemSku?.[0],
            locationType,
            zipCode: zipToSearch,
            startDate: startDeliveryDate,
            variantId: flexTestId || null,
            customerType,
        })
            .then(({ data }) => {
                setCalendarDataLoadingFlag(false);
                this.setState({
                    calendarIsLoading: false,
                    guaranteedCharge: Number(data.response.guaranteedCharge).toFixed(2),
                });
                if (!data?.response?.calendarDates?.length) {
                    handleDeliveryAvailability(false);
                    return;
                }
                this.generateState(data.response.calendarDates);
            })
            .catch((err) => {
                this.setState({ calendarIsLoading: false });
                setCalendarDataLoadingFlag(false);
                handleDeliveryAvailability(false);
                mbpLogger.logError({
                    appName: process.env.npm_package_name,
                    component: 'GraphqlPDPCalendar',
                    apiCall: 'availablityDeliveryCalendar - cart services',
                    jsError: err,
                    jwt,
                    itemSku,
                    brandId,
                    zipCode: zipToSearch,
                    locationType,
                    startDate,
                });
            });
    };

    determineNow = (apiResponse) => {
        // by default the first day shown on the calendar is the beginning of the week of the first availalbe date
        // from the delivery calendar API
        // However sometimes the desired behavior is to show some additional weeks
        // even if all dates for those weeks are unavailable. is-floral-calendar-built-on-current-date controls this behavior
        // if the flag is enabled this function checks to see if the current month is the same as the month from the delivery
        // calendar API. If it is the same month then the first date shown in the calendar is the beginning of the current week
        // not the beginning of the first week with product availability
        const { featureFlags } = this.props;
        const isLeadTimeEnabled = featureFlags['is-floral-calendar-built-on-current-date'];
        return (
            (new Date().getFullYear()) === (new Date(yyyy_mm_ddToDate(apiResponse[0].deliveryDate).getFullYear()))
            && getMonth(new Date()) === getMonth(new Date(yyyy_mm_ddToDate(apiResponse[0].deliveryDate)))
            && isLeadTimeEnabled
        ) ? new Date() : yyyy_mm_ddToDate(apiResponse[0].deliveryDate);
    }

    generateState = (apiResponse) => {
        this.generateCalendarObject(apiResponse);

        this.setState(
            {
                // see comments on function for more details
                now: this.determineNow(apiResponse),
                end: yyyy_mm_ddToDate(
                    apiResponse[apiResponse.length - 1].deliveryDate,
                ),
                spinnerActive: false,
            },
            this.checkPrevButton,
        );
    };

    generateCalendarObject = (apiResponse) => {
        const { altCalendar: { altCalendarData } } = this.state;

        apiResponse.forEach((date) => {
            this.apiDateObj[date.deliveryDate] = date;
        });
        if (altCalendarData && this.determineIfAltCalendarDatesAreInFuture(altCalendarData)) {
            // if the last date to check for the alt calendar already happened then the alt calendar should not activate
            let allDatesAreUnAvailable = true;
            let dayTracker = new Date(altCalendarData.alt_calendar_start_date);
            while (compareAsc(new Date(dayTracker), new Date(altCalendarData.alt_calendar_end_date)) < 1) {
                // loop through the all dates between alt cal start date and alt cal end date
                if (this.apiDateObj[format(dayTracker, 'yyyy-MM-dd')]) {
                    // if there is a date that is available among between alt start and end
                    // set allDatesAreUnAvailable to false and don't trigger alt calendar experience
                    allDatesAreUnAvailable = false;
                }
                dayTracker = addDays(new Date(dayTracker), 1);
            }
            if (allDatesAreUnAvailable === true) {
                this.setState({
                    altCalendar: {
                        altCalendarActivated: true,
                        altCalendarData,
                    },
                });
            }
        }
    };

    determineIfAltCalendarDatesAreInFuture = (altCalendarData) => {
        if (compareAsc(new Date(altCalendarData.alt_calendar_end_date), new Date()) < 1) {
            return false;
        }
        return true;
    }

    isFinalDateDisplayed = (calendar) => {
        const { featureFlags } = this.props;
        if (featureFlags?.['is-calendar-show-week-five-enabled'] || this.isNewDeliveryCalendarEnabled()) {
            const { end } = this.state;
            const calendarWeeks = Object.keys(calendar || {});
            const calendarEndWeek = calendar?.[calendarWeeks?.[calendarWeeks?.length - 1]] || [];
            const calendarEndDate = calendarEndWeek?.[calendarEndWeek?.length - 1]?.date || null;
            const comparison = compareAsc(new Date(end), new Date(calendarEndDate));
            if (comparison > 0) {
                return true;
            }
        }
        return false;
    }

    generateDaysByRange = (start, end) => {
        const days = [];
        if (start && end) {
            let currentDate = yyyy_mm_ddToDate(start);
            while (compareAsc(currentDate, addDays(yyyy_mm_ddToDate(end), 1)) === -1) {
                days.push(formatDateYYYY_MM_DD(currentDate));
                currentDate = addDays(currentDate, 1);
            }
        }
        return days;
    }

    generateCalendar = (now, end, startDayOfWeek) => {
        const { featureFlags } = this.props;
        let calendarDay = startOfWeek(new Date(now), { weekStartsOn: startDayOfWeek });
        const endDay = endOfMonth(end);
        const calendarDays = {};
        const weekCounter = [];
        const weekDaysCounter = {};
        const DayofMonth = format(new Date(now), 'dd');
        const DayofWeek = format(new Date(now), 'eee');
        while (compareAsc(new Date(calendarDay), new Date(endDay)) === -1) {
            const weekNumber = getWeek(new Date(calendarDay), { weekStartsOn: startDayOfWeek });
            if (featureFlags?.['is-calendar-show-week-five-enabled'] || this.isNewDeliveryCalendarEnabled()) {
                if (typeof weekDaysCounter[`week-${weekNumber}`] === 'undefined') {
                    weekDaysCounter[`week-${weekNumber}`] = [];
                }
                weekDaysCounter[`week-${weekNumber}`].push(weekNumber);
                weekCounter.push(weekNumber);
            }
            if (!calendarDays[weekNumber]) {
                calendarDays[weekNumber] = [];
            }
            if (!this.apiDateObj[formatDateYYYY_MM_DD(calendarDay)]) {
                calendarDays[weekNumber].push({
                    date: new Date(calendarDay),
                    available: false,
                });
            } else {
                calendarDays[weekNumber].push({
                    date: new Date(calendarDay),
                    available: true,
                    data: this.apiDateObj[formatDateYYYY_MM_DD(calendarDay)],
                });
            }
            calendarDay = addDays(new Date(calendarDay), 1);
            if ((featureFlags?.['is-calendar-show-week-five-enabled'] || this.isNewDeliveryCalendarEnabled()) && (DayofMonth === '01' && (DayofWeek === 'Sat' || DayofWeek === 'Fri') && startDayOfWeek === 0  ? [...new Set(weekCounter)].length > 5 : [...new Set(weekCounter)].length > 4) && weekDaysCounter[`week-${weekNumber}`]?.length === 7) {
                break;
            }
        }
        return calendarDays;
    };

    generateMonth = (calendar, startWeek, endWeek) => {
        const month = [];
        let weekTracker = startWeek;
        while (weekTracker !== endWeek + 1) {
            if (calendar[weekTracker]?.length) {
                month.push(calendar[weekTracker]);
            }
            weekTracker += 1;
            if (weekTracker === 53 && endWeek !== 53) {
                weekTracker = 1;
            }
        }
        return month;
    };

    checkPrevButton = () => {
        const { now } = this.state;
        const productAvailableMonth = now.getMonth();
        const currentMonth = getNow().getMonth();
        const productAvailableYear = now.getYear();
        const currentYear = getNow().getYear();
        if ((productAvailableMonth === currentMonth && productAvailableYear === currentYear) || ((productAvailableMonth === currentMonth && productAvailableYear === currentYear) && currentMonth < productAvailableMonth && productAvailableMonth - currentMonth === 1)) {
            this.setState({ prevBtnCheck: false });
        } else {
            this.setState({ prevBtnCheck: true });
        }
    };

    isCalendarV2Enabled = () => {
        const { featureFlags } = this.props;
        return featureFlags['is-calendar-and-flex-redesign-enabled'];
    }

    isNewDeliveryCalendarEnabled = () => {
        const { featureFlags, brandId, location } = this.props;
        return featureFlags['is-new-delivery-calendar-enabled'] || (location.pathname.includes('add-ons') && brandId === '1012' && !featureFlags['is-new-delivery-calendar-enabled'] && featureFlags['is-new-delivery-calendar-checkout-enabled']);
    }

    preMonth = () => {
        const { now } = this.state;
        this.setState({
            spinnerActive: true,
        });

        this.getCalendarFromAPI(formatDateYYYY_MM_DD(firstofPrevMonth(now)));
    };

    nextMonth = () => {
        const { now } = this.state;
        this.setState({
            spinnerActive: true,
        });

        this.getCalendarFromAPI(formatDateYYYY_MM_DD(firstOfNextMonth(now)));
    };

    flexAddToCart = (flexDate) => {
        const { flex: { selectedDate } } = this.state;
        const {
            actionAddToCart,
            actionUpdateOrder,
            selectedItemId,
            brandId,
            history,
            userSubmittedZip,
            location,
            orderItemId,
            close,
            deliveryType,
            ageDetails,
            partNumber,
            sympathyValue,
            pageType,
            isInternationalProduct,
            internationalCountry,
            isPassportBundleItem,
            personalization,
            movieSelected,
            isPassportBundleProduct,
            selectedSku,
            hasWine,
            isFlowersSubscriptionFeatureEnabled,
            isFlowersTempV1SubscriptionFeatureEnabled,
            subscription,
            subscriptionDetails,
            handleMiniCartModalClick,
            enableMinicart,

            // category quickview
            zipCode,
            isQuickviewOrigin,
            quickviewSelectDate,
            subscriptionFrequencyData,
            featureFlags,
            showWrapup,

            attributeCategoryId,
            attributeCategoryPath,
            attributeCategoryName,
            isWrapUpEnabled,
            salesforceResponseWrapUpShowHide,
        } = this.props;
        let reconciledZipcode = userSubmittedZip;
        let brandCode = brandId;

        if (isQuickviewOrigin) reconciledZipcode = zipCode;

        if (isQuickviewOrigin || (!location.pathname.includes('checkout') && !location.pathname.includes('add-ons') && (!location.pathname.includes('wrap-up')))) {
            let categoryId = null;
            let categoryName = null;
            let categoryPath = null;

            // if the user direct loads a product page there will be no categoryId
            if (location) {
                categoryPath = location.pathname;

                if (location.state) {
                    const { fromCategoryPage } = location.state;

                    if (fromCategoryPage) {
                        if (location.state.categoryId) {
                            categoryId = location.state.categoryId;
                        }

                        if (location.state.categoryPath) {
                            categoryPath = location.state.categoryPath;
                        }

                        if (location.state.categoryName) {
                            categoryName = location.state.categoryName;
                        }
                    }
                }
            } else {
                categoryId = null;
            }

            const attributes = {
                categoryId: attributeCategoryId || categoryId,
                categoryPath: attributeCategoryPath || categoryPath,
                categoryIdent: attributeCategoryName || categoryName,
            };

            // sympathyValue: '1' - Home, '2' - Funeral
            let skipAddons = false;
            if ((location.state && location.state.skipAddons) || (sympathyValue === '1' || sympathyValue === '2')) {
                skipAddons = true;
            }

            // if passportBundle flow; redirect to /checkout
            let passportBundle = {};
            if (isPassportBundleProduct?.enable) {
                skipAddons = true;
                passportBundle = {
                    enable: true,
                    passportSku: isPassportBundleProduct?.passportSku,
                };
            }

            /* Feature - FPT / GPT - Back Up Product - Start */
            let productCode = selectedItemId;
            let productPartNumber = partNumber;

            const backUpProductBrandCode = selectedDate?.data?.backupSkuBrand;
            const backUpProductSkuCode = selectedDate?.data?.backupSkuCode;

            const backUpProduct = checkDateHasBackUpProduct(productCode, backUpProductBrandCode, backUpProductSkuCode);
            console.log('selectedDate', selectedDate);
            console.log('backUpProduct', backUpProduct);
            if (backUpProduct.hasProduct) {
                console.log('inside');
                brandCode = backUpProductBrandCode;
                productCode = backUpProduct.productCode;
                productPartNumber = backUpProduct.productCode;
            }
            /* Feature - FPT / GPT - Back Up Product - End */

            const locationType = this.getLocationType();

            const quantity = 1;
            const delivery = {
                deliveryDate: format(selectedDate.date, 'MM/dd/yyyy HH:mm:ss'),
                zipCode: reconciledZipcode,
                locationType,
                deliverySLA: selectedDate.data.deliverySLA,
                deliveryDateType: selectedDate.data.calendarDateType,
            };

            if (featureFlags['is-new-flex-flows-enabled']) {
                const flexVariantId = selectedDate?.data?.flexDetails?.variantId;
                const flexOptionKey = flexDate?.optionKey;
                const flexOptionId = flexDate?.optionCode;
                const forcedDate = flexDate?.forcedDelDate;
                if (!flexVariantId?.length || !flexOptionKey?.length || !flexOptionId?.length) {
                    mbpLogger.logError({
                        appName: process.env.npm_package_name,
                        message: 'New Flex Missing Data...',
                        flexVariantId,
                        flexOptionKey,
                        flexOptionId,
                    });
                }

                delivery.flexVariantId = flexVariantId;
                delivery.flexOptionKey = flexOptionKey;
                delivery.flexOptionId = flexOptionId;

                if (selectedDate?.data?.flexDetails?.forceFlex === 'true') {
                    delivery.deliveryDate = format(browserAgnosticNewDate(forcedDate), 'MM/dd/yyyy HH:mm:ss');
                }
            } else {
                delivery.flexVariantId = selectedDate?.data?.flexDetails?.variantId || null;
                delivery.flexOptionKey = flexDate?.optionKey || null;
                delivery.flexOptionId = flexDate.optionCode || null;
            }

            // International Product
            // send countryCode and zipcode
            // zipcode is set to 00000 if the product is international
            if (isInternationalProduct && internationalCountry) {
                delivery.countryCode = internationalCountry;
                delivery.zipCode = '00000';
            }

            let subscriptionData = {};
            if (isFlowersSubscriptionFeatureEnabled && isFlowersTempV1SubscriptionFeatureEnabled
                && subscription?.subscriptionCheckbox) {
                if (Object.keys(subscriptionFrequencyData).length > 0 && featureFlags['is-subscription-frequency']) {
                    subscriptionData = {
                        isSelected: subscription?.subscriptionCheckbox,
                        hasFrequencyList: featureFlags['is-subscription-frequency'],
                        hasDurationList: featureFlags['is-subscription-duration'],
                        duration: subscriptionFrequencyData.duration,
                        interval: subscriptionFrequencyData.subscriptionInterval,
                        type: subscriptionFrequencyData.subscriptionType,
                    };
                } else if (subscriptionDetails
                    && subscriptionDetails.subscriptionDurationKey
                    && subscriptionDetails.subscriptionFrequency
                    && subscriptionDetails.subscriptionType) {
                    let duration = subscriptionDetails.subscriptionDurationKey;
                    if (duration.indexOf('.') > 0) {
                        duration = duration.substring(0, duration.indexOf('.'));
                    }

                    let interval = subscriptionDetails.subscriptionFrequency;
                    if (interval.indexOf('.') > 0) {
                        interval = interval.substring(0, interval.indexOf('.'));
                    }

                    subscriptionData = {
                        isSelected: subscription?.subscriptionCheckbox,
                        hasFrequencyList: featureFlags['is-subscription-frequency'],
                        hasDurationList: featureFlags['is-subscription-duration'],
                        duration,
                        interval,
                        type: subscriptionDetails.subscriptionType,
                    };
                }
            }

            // subscription v1 - temporary hardcode
            if (isFlowersSubscriptionFeatureEnabled && !isFlowersTempV1SubscriptionFeatureEnabled && location?.pathname?.includes('subscription')) {
                subscriptionData = {
                    isSelected: subscription?.subscriptionCheckbox,
                    hasFrequencyList: featureFlags['is-subscription-frequency'],
                    hasDurationList: featureFlags['is-subscription-duration'],
                    duration: 1,
                    interval: 30,
                    type: 'Basic',
                };
            }

            let wineVerify = {};
            if (
                ageDetails && ageDetails.birthDay && ageDetails.birthMonth
                && ageDetails.birthYear && ageDetails.minAge && hasWine === 'true'
            ) {
                wineVerify = {
                    ...ageDetails,
                };
            }

            let personalizationLines;
            const personalizationObj = {
                isPersonalizable: false,
                personalizedData: {},
            };
            if (personalization?.lineState) {
                personalizationLines = personalization.lineState?.map((line, i) => ({
                    perAttributeId: `${i + 1}`,
                    perAttributeValue: personalization.lineState[i].lineText,
                }));

                if (personalizationLines) {
                    personalizationObj.isPersonalizable = true;
                    personalizationObj.personalizedData = {
                        personalizationType: 'PRODUCT_PERSONALIZATION',
                        personalizationLines,
                    };
                }
            }

            const ATC = new AddToCartBuilderObject(
                brandCode,
                (productCode || selectedItemId),
                quantity,
                [], // cyoSelectedComponents
                [], // addons
                null, // productLineItemType
                subscriptionData,
                attributes,
                wineVerify,
                personalizationObj,
                {}, // pmallSummaryInfo
                {}, // giftList,
                null, // deliveryIndicatorPDP for gift list
                null, // deliveryMethod for giftlist
                null, // contactId,
                selectedSku,
                delivery,
            );

            const itemObj = ATC.getPayload();

            // /** supposly is inthe selectedSKUUUUUUUUUUUUU
            //  * Passing price rule id that will use to calculate the price dynamically based on the price rules
            //  */
            // if (selectedSku?.priceRules?.length) {
            //     reqObj.priceRules = selectedSku.priceRules;
            // }

            // Category Page Quickview...
            // 1. Grab the data
            // 2. walk away
            if (isQuickviewOrigin) {
                const payload = {
                    reqObj: itemObj,
                    history,
                    deliveryType, // FPT or GPT
                    categoryId,
                    productPartNumber,
                    skipAddons,
                    date: selectedDate.date,
                    categoryName,
                    categoryPath,
                    isPassportBundle: isPassportBundleItem,
                    movieSelected,
                    selectedSku,
                    passportBundle,
                    handleMiniCartModalClick,
                    enableMinicart,
                    close: noop,
                };
                // grab the data
                quickviewSelectDate(payload);
                // walk away
                close();
                return;
            }

            actionAddToCart({
                item: [itemObj],
                history,
                categoryId,
                partNumber,
                categoryName,
                categoryPath,
                enableMinicart,
                handleMiniCartModalClick,
                isMobile: false,
                eventType: 'Product Page',
                passportBundle,
                movieSelected,
                closeCalendar: close,
                skipAddons,
                showWrapup,
                deliveryDate: selectedDate.date,
                isWrapUpEnabled,
                salesforceResponseWrapUpShowHide,
            });
            // End quickwiew
        } else {
            const payload = {
                updateDeliveryDate: {
                    deliveryDate: format(selectedDate.date, 'MM/dd/yyyy HH:mm:ss'),
                    deliverySLA: selectedDate.data.deliverySLA,
                    deliveryDateType: selectedDate.data.calendarDateType,
                },
                orderItemId,
            };

            if (featureFlags['is-new-flex-flows-enabled']) {
                const flexVariantId = selectedDate?.data?.flexDetails?.variantId;
                const flexOptionKey = flexDate?.optionKey;
                const flexOptionId = flexDate?.optionCode;
                const forcedDate = flexDate?.forcedDelDate;
                if (!flexVariantId?.length || !flexOptionKey?.length || !flexOptionId?.length) {
                    mbpLogger.logError({
                        appName: process.env.npm_package_name,
                        message: 'New Flex Missing Data...',
                        flexVariantId,
                        flexOptionKey,
                        flexOptionId,
                    });
                }

                payload.updateDeliveryDate.flex = {
                    flexVariantId,
                    flexOptionKey,
                    flexOptionId,
                };

                if (selectedDate?.data?.flexDetails?.forceFlex === 'true') {
                    payload.updateDeliveryDate.deliveryDate = format(browserAgnosticNewDate(forcedDate), 'MM/dd/yyyy HH:mm:ss');
                }
            } else {
                payload.updateDeliveryDate.flex = {
                    flexOptionId: flexDate.optionCode,
                    forcedDate: `${flexDate.forcedDelDate},${flexDate.flexDate}`,
                    flexMessages: flexDate.flexMsg.map((message) => ({
                        flxMsg: message.msgText,
                        flxMsgLoc: message.msgTypeLocation,
                    })),
                };
            }

            const updateOrderProps = {
                page: location.pathname.includes('checkout') || location.pathname.includes('add-ons') ? pageType : null,
                updateType: 'delivery',
            };
            actionUpdateOrder(payload, updateOrderProps, close);
        }
    }

    handleSelectedDateOnClick = (dateObj) => {
        this.setState({
            dateObj,
        });
    }

    selectDate = (selectedDate) => {
        const { clickableUnavailableDates, exactButtonClicked } = this.state;
        const {
            actionAddToCart,
            actionUpdateOrder,
            selectedItemId,
            brandId,
            history,
            userSubmittedZip,
            location,
            orderItemId,
            close,
            deliveryType,
            ageDetails,
            partNumber,
            sympathyValue,
            pageType,
            trackEvent,
            isInternationalProduct,
            internationalCountry,
            isPassportBundleItem,
            personalization,
            movieSelected,
            isPassportBundleProduct,
            selectedSku,
            hasWine,
            isFlowersSubscriptionFeatureEnabled,
            isFlowersTempV1SubscriptionFeatureEnabled,
            subscription,
            subscriptionDetails,
            handleMiniCartModalClick,
            enableMinicart,

            // category quickview
            zipCode,
            isQuickviewOrigin,
            quickviewSelectDate,

            // pmall
            pmallSummaryInfo,
            subscriptionFrequencyData,
            featureFlags,
            showWrapup,
            cmsData,
            attributeCategoryId,
            attributeCategoryPath,
            attributeCategoryName,
            displayDeliveryCalendar,
            isWrapUpEnabled,
            salesforceResponseWrapUpShowHide,
        } = this.props;
        let brandCode = brandId;
        const surcharge = selectedDate.data.surcharge;
        localStorage.setItem('totalSurcharge', surcharge);
        let reconciledZipcode = userSubmittedZip;
        if (isQuickviewOrigin) reconciledZipcode = zipCode;

        const filterFlexImageData = cmsData?.entries?.[0]?.calendar_date_icons?.filter((item) => item?.date === selectedDate?.data?.deliveryDate);
        if (selectedDate.data.flexDetails?.option) {
            this.setState({
                flex: {
                    flexActivated: true,
                    flexData: selectedDate.data.flexDetails.option,
                    selectedDate,
                    dateFlexImage: filterFlexImageData?.[0]?.flex_image || cmsData?.entries?.[0]?.flex_image,
                    showFlexPopup: selectedDate.data.flexDetails.showPopup,
                },
                flexTaggingData: {
                    zipcode: userSubmittedZip,
                    deliverytype: deliveryType,
                    date: format(selectedDate.date, 'yyyy-MM-dd'),
                },
            });
            trackEvent({
                eventCategory: 'Product Page',
                eventAction: `Flex Shown - ${format(selectedDate?.date, 'yyyy-MM-dd')}`,
                eventLabel: `${deliveryType} | ${userSubmittedZip}`,
            });
            return;
        }

        if (location.pathname.includes('add-ons')) {
            this.setState({ dateObj: selectedDate });
        }
        if (selectedDate.data.calendarDateType === 'on' || (selectedDate.data.calendarDateType === 'between' && exactButtonClicked)) {
            addOnsDeliveryDatetype = 'on';
        }
        if (selectedDate.data.calendarDateType === 'between' && !exactButtonClicked) {
            addOnsDeliveryDatetype = 'between';
        }

        if (isQuickviewOrigin || (!location.pathname.includes('checkout') && !location.pathname.includes('add-ons') && !location.pathname.includes('wrap-up'))) {
            let categoryId = null;
            let categoryName = null;
            let categoryPath = null;
            // if the user direct loads a product page there will be no categoryId

            if (location) {
                categoryPath = location.pathname;

                if (location.state) {
                    const { fromCategoryPage } = location.state;

                    if (fromCategoryPage) {
                        if (location.state.categoryId) {
                            categoryId = location.state.categoryId;
                        }

                        if (location.state.categoryPath) {
                            categoryPath = location.state.categoryPath;
                        }

                        if (location.state.categoryName) {
                            categoryName = location.state.categoryName;
                        }
                    }
                }
            } else {
                categoryId = null;
            }

            const attributes = {
                categoryId: attributeCategoryId || categoryId,
                categoryPath: attributeCategoryPath || categoryPath,
                categoryIdent: attributeCategoryName || categoryName,
            };

            let skipAddons = false;
            // sympathyValue: '1' - Home, '2' - Funeral
            if ((location.state && location.state.skipAddons) || (sympathyValue === '1' || sympathyValue === '2')) {
                skipAddons = true;
            }

            // if passportBundle flow; redirect to /checkout
            let passportBundle = {};
            if (isPassportBundleProduct?.enable) {
                skipAddons = true;
                passportBundle = {
                    enable: true,
                    passportSku: isPassportBundleProduct?.passportSku,
                };
            }

            /* Feature - FPT / GPT - Back Up Product - Start */
            let productCode = selectedItemId;
            let productPartNumber = partNumber;

            const backUpProductBrandCode = selectedDate?.data?.backupSkuBrand;
            const backUpProductSkuCode = selectedDate?.data?.backupSkuCode;

            const backUpProduct = checkDateHasBackUpProduct(productCode, backUpProductBrandCode, backUpProductSkuCode);

            console.log('selectedDate', selectedDate);
            console.log('backUpProduct', backUpProduct);
            if (backUpProduct.hasProduct) {
                console.log('inside');
                brandCode = backUpProductBrandCode;
                productCode = backUpProduct.productCode;
                productPartNumber = backUpProduct.productCode;
            }
            /* Feature - FPT / GPT - Back Up Product - End */

            const locationType = this.getLocationType();
            const quantity = 1;

            const delivery = {
                deliveryDate: format(selectedDate.date, 'MM/dd/yyyy HH:mm:ss'),
                deliveryDateType: selectedDate.data.calendarDateType,
                zipCode: reconciledZipcode,
                locationType,
                deliverySLA: selectedDate.data.deliverySLA,
            };
            if (selectedDate.data.calendarDateType === 'between' && !exactButtonClicked) {
                delivery.deliverRangeStartDate = format(new Date(`${selectedDate.data.rangeStartDate} 00:00:00`), 'MM/dd/yyyy HH:mm:ss');
            } else if (selectedDate.data.calendarDateType === 'between' && exactButtonClicked) {
                delivery.deliveryDateType = 'on';
                delivery.deliverySLA = 'GUARANTEED';
                delete delivery.deliverRangeStartDate;
            }
            if (!featureFlags['is-new-flex-flows-enabled']
               && selectedDate?.data?.isClickableUnavailableDate
               && clickableUnavailableDates?.fakeDateInfo?.flex_option_id
               && clickableUnavailableDates?.fakeDateInfo?.flex_forceddeldate
               && clickableUnavailableDates?.fakeDateInfo?.flex_date) {
                const { fakeDateInfo } = clickableUnavailableDates;
                delivery.flexVariantId = fakeDateInfo?.flexVariantId;
                delivery.flexOptionKey = fakeDateInfo?.flex_option_key; // TODO: look if exist
                delivery.flexOptionId = fakeDateInfo?.flex_option_id;
                console.error('fakeDateInfo', fakeDateInfo);
            }

            if (selectedDate?.data?.isClickableUnavailableDate
                && clickableUnavailableDates?.fakeDateInfo?.flex_option_id
                && clickableUnavailableDates?.fakeDateInfo?.flex_forceddeldate
                && clickableUnavailableDates?.fakeDateInfo?.flex_date) {
                const { fakeDateInfo } = clickableUnavailableDates;
                delivery.flexVariantId = fakeDateInfo?.flexVariantId;
                delivery.flexOptionKey = fakeDateInfo?.flex_option_key; // TODO: look if exist
                delivery.flexOptionId = fakeDateInfo?.flex_option_id;
                console.error('fakeDateInfo', fakeDateInfo);
            }

            // International Product
            // send countryCode and zipcode
            // zipcode is set to 00000 if the product is international
            if (isInternationalProduct && internationalCountry) {
                delivery.countryCode = internationalCountry;
                delivery.zipCode = '00000';
            }

            let wineVerify = {};
            if (
                ageDetails && ageDetails.birthDay && ageDetails.birthMonth
                && ageDetails.birthYear && ageDetails.minAge && hasWine === 'true'
            ) {
                wineVerify = {
                    ...ageDetails,
                };
            }

            let subscriptionData = {};
            if (isFlowersSubscriptionFeatureEnabled && isFlowersTempV1SubscriptionFeatureEnabled
                && subscription?.subscriptionCheckbox) {
                if (Object.keys(subscriptionFrequencyData).length > 0 && featureFlags['is-subscription-frequency']) {
                    subscriptionData = {
                        isSelected: subscription?.subscriptionCheckbox,
                        hasFrequencyList: featureFlags['is-subscription-frequency'],
                        hasDurationList: featureFlags['is-subscription-duration'],
                        duration: subscriptionFrequencyData.duration,
                        interval: subscriptionFrequencyData.subscriptionInterval,
                        type: subscriptionFrequencyData.subscriptionType,
                    };
                } else if (subscriptionDetails
                    && subscriptionDetails.subscriptionDurationKey
                    && subscriptionDetails.subscriptionFrequency
                    && subscriptionDetails.subscriptionType) {
                    let duration = subscriptionDetails.subscriptionDurationKey;
                    if (duration.indexOf('.') > 0) {
                        duration = duration.substring(0, duration.indexOf('.'));
                    }

                    let interval = subscriptionDetails.subscriptionFrequency;
                    if (interval.indexOf('.') > 0) {
                        interval = interval.substring(0, interval.indexOf('.'));
                    }

                    subscriptionData = {
                        isSelected: subscription?.subscriptionCheckbox,
                        hasFrequencyList: featureFlags['is-subscription-frequency'],
                        hasDurationList: featureFlags['is-subscription-duration'],
                        duration,
                        interval,
                        type: subscriptionDetails.subscriptionType,
                    };
                }
            }

            // subscription v1 - temporary hardcode
            if (isFlowersSubscriptionFeatureEnabled && !isFlowersTempV1SubscriptionFeatureEnabled && location?.pathname?.includes('subscription')) {
                subscriptionData = {
                    isSelected: subscription?.subscriptionCheckbox,
                    hasFrequencyList: featureFlags['is-subscription-frequency'],
                    hasDurationList: featureFlags['is-subscription-duration'],
                    duration: 1,
                    interval: 30,
                    type: 'Basic',
                };
            }

            /**
             * Handle req object personlization fields
             * for internal products
             */
            let personalizationLines;
            const personalizationObj = {
                isPersonalizable: false,
                personalizedData: {},
            };

            if (personalization?.lineState) {
                personalizationLines = personalization.lineState?.map((line, i) => ({
                    perAttributeId: `${i + 1}`,
                    perAttributeValue: personalization.lineState[i].lineText,
                }));

                if (personalizationLines) {
                    personalizationObj.isPersonalizable = true;
                    personalizationObj.personalizedData = {
                        personalizationType: 'PRODUCT_PERSONALIZATION',
                        personalizationLines,
                    };
                }
            }

            const ATC = new AddToCartBuilderObject(
                brandCode,
                (productCode || selectedItemId),
                quantity,
                [], // cyoSelectedComponents
                [], // addons
                null, // productLineItemType
                subscriptionData,
                attributes,
                wineVerify,
                personalizationObj,
                pmallSummaryInfo, // pmallSummaryInfo
                {}, // giftList,
                null, // deliveryIndicatorPDP for gift list
                null, // deliveryMethod for giftlist
                null, // contactId,
                selectedSku,
                delivery,
            );

            const itemObj = ATC.getPayload();

            // /**
            //  * Passing price rule id that will use to calculate the price dynamically based on the price rules
            //  */
            // if (selectedSku?.priceRules?.length) {
            //     reqObj.priceRules = selectedSku.priceRules;
            // }

            // Category Page Quickview...
            // 1. Grab the data
            // 2. walk away
            if (isQuickviewOrigin) {
                const payload = {
                    reqObj: itemObj,
                    history,
                    deliveryType, // FPT or GPT
                    categoryId,
                    productPartNumber,
                    skipAddons,
                    date: selectedDate.date,
                    categoryName,
                    categoryPath,
                    isPassportBundleItem,
                    movieSelected,
                    selectedSku,
                    passportBundle,
                    handleMiniCartModalClick,
                    enableMinicart,
                    close: noop,
                };
                // grab the data
                quickviewSelectDate(payload);
                // walk away
                close();
                return;
            }

            trackEvent({
                eventCategory: 'Product Page',
                eventAction: 'Add to Cart - Calendar',
                eventLabel: productCode,
            });

            trackEvent({
                eventCategory: 'Product Page',
                eventAction: 'Calendar Date Selected',
                eventLabel: format(selectedDate.date, 'yyyy-MM-dd'),
            });

            actionAddToCart({
                item: [itemObj],
                history,
                categoryId,
                partNumber,
                categoryName,
                categoryPath,
                enableMinicart,
                handleMiniCartModalClick,
                isMobile: false,
                eventType: 'Product Page',
                passportBundle,
                movieSelected,
                closeCalendar: close,
                skipAddons,
                showWrapup,
                deliveryDate: selectedDate.date,
                displayDeliveryCalendar,
                isWrapUpEnabled,
                salesforceResponseWrapUpShowHide,
            });
        } else {
            const payload = {
                updateDeliveryDate: {
                    deliveryDate: format(selectedDate.date, 'MM/dd/yyyy HH:mm:ss'),
                    deliverySLA: selectedDate.data.deliverySLA,
                    deliveryDateType: selectedDate.data.calendarDateType,
                },
                orderItemId,
            };
            if (selectedDate.data.calendarDateType === 'between' && !exactButtonClicked) {
                payload.updateDeliveryDate.deliverRangeStartDate = format(new Date(`${selectedDate.data.rangeStartDate} 00:00:00`), 'MM/dd/yyyy HH:mm:ss');
            } else if (selectedDate.data.calendarDateType === 'between' && exactButtonClicked) {
                payload.updateDeliveryDate.deliveryDateType = 'on';
                delete payload.updateDeliveryDate.deliverRangeStartDate;
            }
            if (selectedDate?.data?.isClickableUnavailableDate
                && clickableUnavailableDates?.fakeDateInfo?.flex_option_id
                && clickableUnavailableDates?.fakeDateInfo?.flex_forceddeldate
                && clickableUnavailableDates?.fakeDateInfo?.flex_date) {
                const { fakeDateInfo } = clickableUnavailableDates;
                const splitForcedDelDate = fakeDateInfo.flex_forceddeldate?.split('-')?.join('/');
                const formattedForcedDelDate = format(new Date(splitForcedDelDate), 'dd-MMM-yy');
                const splitFlexDate = fakeDateInfo.flex_date?.split('-')?.join('/');
                const formattedSplitDate = format(new Date(splitFlexDate), 'dd-MMM-yy');
                payload.updateDeliveryDate.flex = {
                    flexOptionId: fakeDateInfo?.flex_option_id,
                    forcedDate: `${formattedForcedDelDate},${formattedSplitDate}`,
                };
            }

            const updateOrderProps = {
                page: location.pathname.includes('checkout') ? pageType : null,
                updateType: 'delivery',
            };
            actionUpdateOrder(payload, updateOrderProps, close);
            trackEvent({ eventCategory: 'Cart Page', eventAction: 'Delivery Date Updated', eventLabel: format(selectedDate.date, 'yyyy-MM-dd') });
        }
    };

    mouseOverHandler = (surcharge, date) => {
        const { deviceType } = this.props;
        if (deviceType !== 'mobile') {
            this.setState({ surcharge, focusedDate: date });
        }
    };

    handleFlexClose = () => {
        this.setState({
            flex: {},
        });
    };

    determineDateColor = (typeOfDate, isFocused, isHoverDisableOnDesktop, dayHasBanner = false, boxDate = '') => {
        if (typeOfDate === 'unavailable') {
            // unavailable dates do not change their styling based on hovering so this function does not return any additional
            // inline styles for unavailable dates
            return null;
        }

        // The default, unfocused view of a date is a white background with the date text as specific color (determined by CMS)
        // When the user focuses on a date the date text becomes white and the background color becomes what was the date text color
        const { cmsData, inclementWeatherBanner } = this.props;
        const flexHolidayBanner = this.getHolidayBanner();
        const serviceLevels = cmsData?.entries?.[0]?.service_levels || [];
        if (dayHasBanner && inclementWeatherBanner?.banner?.background?.color) {
            const hov = isFocused === true && !isHoverDisableOnDesktop;
            return { color: hov ? inclementWeatherBanner.banner.background.color : '#52297E', background: hov ? '#52297E' : inclementWeatherBanner.banner.background.color };
        }

        if (dayHasBanner && flexHolidayBanner?.banner?.background_color?.color) {
            const dateSpecificStyle = flexHolidayBanner?.date_styling?.day || [];
            const boxStyle = (dateSpecificStyle || []).find((day) => day?.date === boxDate) || null;
            const hov = isFocused === true && !isHoverDisableOnDesktop;
            if (boxStyle && boxStyle?.date) {
                return {
                    color: hov ? boxStyle?.text_color_on_hover?.color || flexHolidayBanner?.banner?.background_color?.color : boxStyle?.text_color?.color || '#52297E',
                    background: hov ? boxStyle?.background_color_on_hover?.color || '#52297E' : boxStyle?.background_color?.color || flexHolidayBanner?.banner?.background_color?.color,
                };
            }
            return { color: hov ? flexHolidayBanner?.banner?.background_color?.color : '#52297E', background: hov ? '#52297E' :  flexHolidayBanner?.banner?.background_color?.color };
        }

        if (isFocused === true && !isHoverDisableOnDesktop) {
            if (typeOfDate === 'flex') {
                return {
                    color: 'white', display: 'flex', flexDirection: 'column', background: serviceLevels.find((level) => level.type === typeOfDate)?.color || '#2F7BA6',
                };
            }
            if (serviceLevels.find((level) => level.type === typeOfDate)?.color !== '#52297E') {
                return {
                    color: 'white', background: serviceLevels.find((level) => level.type === typeOfDate)?.color || '#2F7BA6',
                };
            }
            // if focused set the color to white and the background to the right color as determined by CMS.
            return { color: 'white', background: serviceLevels.find((level) => level.type === typeOfDate)?.color || 'black' };
        }
        // else set the color to the CMS determined color and keep the background as its default white
        return { color: serviceLevels.find((level) => level.type === typeOfDate)?.color };
    }

    determineDateType = (dateObj, fakeSurcharge) => {
        // more date types and checks will be added as needed.
        const dateInfo = dateObj.data;
        if (dateInfo === undefined) {
            // If there is no information object in the date object the date is not available
            return 'unavailable';
        }
        if (dateInfo?.flexDetails !== undefined) {
            return 'flex';
        } if (fakeSurcharge || Number(dateInfo.surcharge) > 0 || Number(dateInfo.upcharge) > 0) {
            return 'surcharge';
        }
        return 'regular';
    }

    getFlexSurcharge = (flexDetails) => {
        const { cmsData, presentationFamily } = this.props;
        if (!Array.isArray(flexDetails) || !flexDetails.length) {
            return 0;
        }
        const showLowestAvailbeSurcharge = cmsData?.entries?.[0]?.surcharge_conditions?.show_lowest_available_surcharge;
        const maxSurcharge = Math.max(...flexDetails.map((o) => Number(o.flexAmount)));
        const minSurcharge = Math.min(...flexDetails.map((o) => Number(o.flexAmount)));
        return showLowestAvailbeSurcharge && presentationFamily === 'flower' ? minSurcharge.toFixed(2) : maxSurcharge.toFixed(2);
    }

    getFlexSurchargeAll = (flexDetails) => {
        if (!Array.isArray(flexDetails) || !flexDetails.length) {
            return 0;
        }
        const minSurcharge = Math.min(...flexDetails.map((o) => Number(o.flexAmount)));
        return minSurcharge;
    }

    // TODO: this is now only for old flow
    handleUnavailableClickAbleDatePress = (fakeDateInfo) => {
        // this fucntion is used to toggle the modal.
        // the open modal invocation passes fakeDateInfo and that info is used to set state
        // the close modal invocation passes no date info and so fakeDateInfo is reset to empty obj
        const { clickableUnavailableDates } = this.state;
        if (fakeDateInfo) {
            this.setState({
                clickableUnavailableDates: {
                    ...clickableUnavailableDates,
                    unavailableDateModalActive: !clickableUnavailableDates.unavailableDateModalActive,
                    fakeDateInfo,
                },
            });
        } else {
            this.setState({
                clickableUnavailableDates: {
                    ...clickableUnavailableDates,
                    unavailableDateModalActive: !clickableUnavailableDates.unavailableDateModalActive,
                    fakeDateInfo: {},
                },
            });
        }
    }

    determineNegativeSurchargeDisplay = (totSurcharge) => `${'$'}${Math.abs(totSurcharge).toFixed(2)}`

    determineSurchargeDisplay = (totSurcharge) => {
        const { cmsData, activeABTests } = this.props;
        const surchargeDisplayVariant = cmsData?.entries?.[0]?.negative_surcharge_formats || '';
        const negativeSurcharge = activeABTests?.negative_surcharge_date || null;
        const hasNegativeSurcharge = totSurcharge < 0;
        if (hasNegativeSurcharge) {
            if (negativeSurcharge === 'variant 1' || (surchargeDisplayVariant === 'n off' && !negativeSurcharge)) {
                if (negativeSurcharge === 'variant 1') {
                    return `${'$'}${Math.abs(totSurcharge)}\nOFF`;
                }
                return `${'$'}${Math.abs(totSurcharge).toFixed(2)}\nOFF`;
            }
            if (negativeSurcharge === 'variant 2' || (surchargeDisplayVariant === 'save n' && !negativeSurcharge)) {
                if (negativeSurcharge === 'variant 2') {
                    return `${'SAVE\n$'}${Math.abs(totSurcharge)}`;
                }
                return `${'Save\n$'}${Math.abs(totSurcharge).toFixed(2)}`;
            }
            return `${'-$'}${Math.abs(totSurcharge).toFixed(2)}`;
        }

        return `${'+$'}${Math.abs(totSurcharge).toFixed(2)}`;
    }

    renderWeekDay = (day, dateObj, month, bannerRange = [], week = []) => {
        const {
            focusedDate, dateIcons, clickableUnavailableDates: { clickableDates }, exactButtonClicked, exactDateOnly, rangeButtonClicked,
        } = this.state;
        const {
            classes, cmsData, locationType, productSkus, activeABTests, trackEvent, deviceType, deliveryType,
        } = this.props;
        this.dataObject = dateObj;
        const negativeSurcharge = activeABTests?.negative_surcharge_date || null;
        let { available, date } = dateObj;
        const dayHasBanner = bannerRange?.length > 0 ? bannerRange.includes(formatDateYYYY_MM_DD(new Date(date))) : false;
        const dateToFocus = date;
        const dateIsFocused = compareAsc(new Date(focusedDate), new Date(dateObj.date)) === 0;
        const serviceLevels = cmsData?.entries?.[0]?.service_levels || [];
        const surchargeDisplayVariant = cmsData?.entries?.[0]?.negative_surcharge_formats || '';
        const negativeSurchargeEndText = cmsData?.entries?.[0]?.negative_surcharge_ending_text || '';
        const isClickableUnavailableDate = !available && clickableDates && clickableDates[format(new Date(date), 'yyyy/MM/dd')];
        let fakeDateInfo = {};
        if (clickableDates) {
            fakeDateInfo = clickableDates[format(new Date(date), 'yyyy/MM/dd')];
        }
        const actualAddToCartDate = fakeDateInfo?.add_to_cart_date;
        let fakeSurcharge = null;
        if (!available && isClickableUnavailableDate && actualAddToCartDate && this.apiDateObj[actualAddToCartDate]) {
            const addToCartDateData = this.apiDateObj[actualAddToCartDate];
            dateObj.available = true;
            dateObj.data = addToCartDateData;
            if (fakeDateInfo?.is_surcharge_override_enabled && typeof fakeDateInfo?.surcharge !== 'undefined') {
                // by defauly take surcharges and upcharges from the actual add to cart date
                // however there is a cMS option to override that surcharge with a cms specified value
                fakeSurcharge = fakeDateInfo?.surcharge;
            }
            dateObj.data.isClickableUnavailableDate = true;
            const addToCartDateFormatted = addToCartDateData?.deliveryDate?.split('-')?.join('/');
            dateObj.date = new Date(addToCartDateFormatted);
            ({ available, date } = dateObj);
        }
        // The text color and background color of each date is determined by the type of date (surcharge, flex, holiday etc.)
        // and whether the user is currently hovering over that date.
        // The colors used come from CMS.
        // The typeOfDate function determines what type of date an individual date is.
        // the dateStyle functions returns an object that can be consumed by react as an inline style
        // see individual function implementations for descriptions of what they do.

        const { presentationFamily } = this.props;
        const isSurchargeAvailable = cmsData?.entries?.[0] || [];
        const surchargeCondition = isSurchargeAvailable?.surcharge_conditions || {};
        const isHoverDisableOnDesktop = surchargeCondition?.disable_surcharge_hover_on_desktop && presentationFamily === 'flower';
        const isSurchargeDisableOnMobile = surchargeCondition?.hide_surcharge_on_mobile && presentationFamily === 'flower';
        const isMobileStyleSurchargeEnabledOnDesktop = surchargeCondition?.enable_mobile_style_surcharge_on_desktop && presentationFamily === 'flower';
        const calendarCellStyling = isHoverDisableOnDesktop ? classes.renderSurchargeWithoutHover : classes.hoverSurcharge;
        const typeOfDate = this.determineDateType(dateObj, fakeSurcharge);
        const dateStyle = this.determineDateColor(typeOfDate, dateIsFocused, isHoverDisableOnDesktop, dayHasBanner, format(new Date(dateObj.date), 'yyyy-MM-dd'), bannerRange);
        const formattedDate = format(new Date(dateObj.date), 'yyyy-MM-dd');
        const todayFormattedDate = format(new Date(), 'yyyy-MM-dd');
        const tomorrowFormattedDate = format(addDays(new Date(), 1), 'yyyy-MM-dd');
        let totalSurcharge = fakeSurcharge || (Number(dateObj?.data?.surcharge || 0) + Number(dateObj?.data?.upcharge || 0)).toFixed(2);
        const renderSurchargeOnDate = () => {
            const { guaranteedCharge } = this.state;
            if (Number(totalSurcharge) !== 0 && !rangeButtonClicked) {
                return (
                    <p className={((negativeSurcharge === 'variant 1' || negativeSurcharge === 'variant 2')) ? `${Number(totalSurcharge) < 0 ? `${classes.surchargeV2} ${classes.negativeSurchargeColor}` : classes.surchargeV2}` : classes.surcharge} style={{ color: this.isNewDeliveryCalendarEnabled() && '#411F1F', fontWeight: this.isNewDeliveryCalendarEnabled() && 'normal' }}>
                        {this.determineSurchargeDisplay(Number(totalSurcharge))}
                    </p>
                );
            }

            if (this.isNewDeliveryCalendarEnabled() && exactButtonClicked && dateObj?.data?.surcharge === '0.00' && dateObj?.data?.upcharge === '0.0' && bannerRange.indexOf(formattedDate) === 0 && (bannerRange[bannerRange.length - 1] === formattedDate) && (formattedDate === todayFormattedDate || tomorrowFormattedDate)) {
                if (!guaranteedCharge) {
                    return null;
                }
                return (
                    <p className={((negativeSurcharge === 'variant 1' || negativeSurcharge === 'variant 2')) ? `${Number(totalSurcharge) < 0 ? `${classes.surchargeV2} ${classes.negativeSurchargeColor}` : classes.surchargeV2}` : classes.surcharge} style={{ color: this.isNewDeliveryCalendarEnabled() && '#411F1F' }}>
                        {guaranteedCharge !== 'NaN' && guaranteedCharge !== '0.00' && this.determineSurchargeDisplay(Number(guaranteedCharge))}
                    </p>
                );
            }
            return null;
        };
        // date box styling for new calendar V3 currently only showing for CCO same day products
        let styleBtn;
        if (this.isNewDeliveryCalendarEnabled() && deviceType !== 'mobile') {
            if (exactButtonClicked && bannerRange.indexOf(formattedDate) < 0) { // date unselected styling
                styleBtn = {
                    color: '#000000', background: '#FFFFFF', border: '1px solid #E9E9E9',
                };
            } else if (!exactButtonClicked && bannerRange.indexOf(formattedDate) >= 0) { // date selected styling for cells in between the first and last dates in a date range
                styleBtn = {
                    color: '#000000', background: '#BFE2EA', border: 'none', width: '72px', margin: '4px 0px', transform: 'scale(1.05)', paddingRight: '9px',
                };
            }
            if (!exactButtonClicked && bannerRange.indexOf(formattedDate) >= 0 && (formattedDate === week?.[6]?.data?.deliveryDate)) { // date selected styling in between range where date is last day of week
                styleBtn = {
                    color: '#000000', background: '#BFE2EA', border: 'none', width: '63px', margin: '4px 5px 4px 0px', transform: 'scale(1.05)',
                };
            }
            if (!exactButtonClicked && bannerRange.indexOf(formattedDate) >= 0 && ((formattedDate === week?.[0]?.data?.deliveryDate) || (bannerRange.includes(formattedDate) && !available && formattedDate === format(new Date(week?.[0]?.date), 'yyyy-MM-dd')))) { // date selected styling in between range where date is first day of new week row
                styleBtn = {
                    color: '#000000', background: '#BFE2EA', border: 'none', width: '67px', margin: '4px 3px 4px 6px', transform: 'scale(1.05)', paddingRight: '6px',
                };
            }
            if (!exactButtonClicked && bannerRange.indexOf(formattedDate) >= 0 && ((bannerRange[0] === formattedDate) || isFirstDayOfMonth(subDays(new Date(formattedDate), -1))) && !isLastDayOfMonth(subDays(new Date(formattedDate), -1))) { // cell styling for first date in a date range
                styleBtn = {
                    color: '#000000', background: '#BFE2EA', border: 'none', margin: '4px 0px 4px 7px', width: '69px', paddingRight: '9px', transform: 'scale(1.05)',
                };
            }
            if ((!exactButtonClicked && bannerRange.indexOf(formattedDate) >= 0 && (bannerRange[0] === formattedDate) && (formattedDate === week?.[6]?.data?.deliveryDate)) || (!exactButtonClicked && bannerRange.indexOf(formattedDate) >= 0 && bannerRange[bannerRange.length - 1] === formattedDate && isFirstDayOfMonth(subDays(new Date(formattedDate), -1))) || (!exactButtonClicked && bannerRange.indexOf(formattedDate) >= 0 && (bannerRange[0] === formattedDate) && isLastDayOfMonth(subDays(new Date(formattedDate), -1)))) { // cell styling for first date in a date range where first date is last day in week
                styleBtn = { color: '#000000', background: '#BFE2EA', border: 'none' };
            }
            if (!exactButtonClicked && bannerRange.indexOf(formattedDate) >= 0 && ((bannerRange[bannerRange.length - 1] === formattedDate && !isFirstDayOfMonth(subDays(new Date(formattedDate), -1))) || (bannerRange[bannerRange.length - 1] !== formattedDate && bannerRange[0] !== formattedDate && isLastDayOfMonth(subDays(new Date(formattedDate), -1))))) { // cell styling for last date in a date range or last day of month within range
                styleBtn = {
                    color: '#000000', background: '#BFE2EA', border: 'none', margin: '4px 6px 4px 0px', width: '63px', transform: 'scale(1.05)',
                };
            }
            if (exactButtonClicked && bannerRange.indexOf(formattedDate) === 0 && (bannerRange[bannerRange.length - 1] === formattedDate) && (formattedDate === todayFormattedDate || tomorrowFormattedDate)) { // exact date cell styling
                styleBtn = { color: '#000000', background: '#BFE2EA', border: 'none' };
            }
        }
        if (this.isNewDeliveryCalendarEnabled() && deviceType === 'mobile') { // date cell styling for mobile devices
            if ((exactButtonClicked && bannerRange.indexOf(formattedDate) >= 0 && (bannerRange[bannerRange.length - 1] === formattedDate)) ||  (!exactButtonClicked && bannerRange.indexOf(formattedDate) >= 0)) {
                styleBtn = {
                    color: '#000000', background: '#BFE2EA', border: 'none',
                };
            }
        }
        let dateHasIcon = false;
        let dateIconData = null;
        if (dateIcons[formattedDate] && dateIcons[formattedDate]?.icon && !['Funeral home', 'Church'].includes(locationType)) {
            dateHasIcon = true;
            dateIconData = { iconData: dateIcons[formattedDate].icon };
        }

        let priceSheetIconsData = null;
        if (dateIcons[formattedDate] && dateIcons[formattedDate]?.is_price_sheet_exclusion_for_icon_enabled) {
            priceSheetIconsData = {
                isPriceSheetExclusionEnabled: dateIcons[formattedDate]?.is_price_sheet_exclusion_for_icon_enabled || false,
                priceSheetExclusionValues: dateIcons[formattedDate]?.price_sheets_where_icon_is_not_shown || [],
            };
        }

        let isPriceSheetValueIncluded = false;
        if (priceSheetIconsData?.priceSheetExclusionValues !== undefined && priceSheetIconsData?.isPriceSheetExclusionEnabled) {
            isPriceSheetValueIncluded = priceSheetIconsData?.priceSheetExclusionValues.includes(productSkus?.[0]?.priceSheet);
        }

        if (date.getMonth() !== month) {
            return (
                <span
                    key={day}
                    className={this.isNewDeliveryCalendarEnabled() ? classes.noDateAvailableRedesignV2 : classes.noDateAvailableRedesign}
                    style={{
                        color: serviceLevels.find((level) => level.type === 'unavailable')?.color || null,
                    }}
                >
                    {/* use &nbsp char to retain box spacing */}
                    &nbsp;
                </span>
            );
        }
        if ((!available && !this.isNewDeliveryCalendarEnabled()) || (this.isNewDeliveryCalendarEnabled() && !available && !bannerRange.includes(formattedDate))) {
            return (
                <UnavailableDateBox
                    classes={classes}
                    day={day}
                    serviceLevels={serviceLevels}
                    dateHasIcon={dateHasIcon}
                    dateIconData={dateIconData}
                    dateIsFocused={dateIsFocused}
                    ccoCalendarFlag={this.isNewDeliveryCalendarEnabled()}
                    deviceType={deviceType}
                />
            );
        }
        const standardCharge = deliveryType === 'FPT' ? 'Standard Service Charge' : 'Standard Shipping Charge';
        if (dateObj?.data?.flexDetails && Number(totalSurcharge) === 0) {
            totalSurcharge = this.getFlexSurcharge(dateObj.data.flexDetails?.option);
        }
        let serviceChargeText = cmsData?.entries?.[0]?.type?.split(' ')[0] === 'FPT'
            ? 'Service Charge'
            : 'Shipping Charge';
        const hasNegativeSurcharge = Number(totalSurcharge) < 0;
        if (hasNegativeSurcharge) {
            // different text used for negative surcharge dates. || used to provde fallback if no text provided from CMS
            serviceChargeText = cmsData?.entries?.[0]?.negative_surcharge_text || serviceChargeText;
        }
        let serviceCharge;
        if (hasNegativeSurcharge) {
            if (surchargeDisplayVariant === 'n off') {
                serviceCharge = `${serviceChargeText} ${'$'}${Math.abs(totalSurcharge).toFixed(2)}\nOFF ${negativeSurchargeEndText}`;
            } else if (surchargeDisplayVariant === 'save n') {
                serviceCharge = `${serviceChargeText} ${'Save\n$'}${Math.abs(totalSurcharge).toFixed(2)} ${negativeSurchargeEndText}`;
            } else {
                serviceCharge = `${serviceChargeText} -$${Math.abs(totalSurcharge).toFixed(2)} ${negativeSurchargeEndText}`;
            }
        } else {
            serviceCharge = `${serviceChargeText} +$${totalSurcharge}`;
        }
        if (dateObj?.data?.flexDetails?.option) {
            const minSurcharge = this.getFlexSurchargeAll(dateObj.data.flexDetails?.option);
            if (Number(minSurcharge) < 0) {
                if (!this.negativeSurchargeAmount) {
                    this.negativeSurchargeAmount = minSurcharge;
                }
                if (!this.lockNegativeSurcharge) {
                // collecting days those contain the negative price
                    this.negativeSurchargeDates.push(format(new Date(dateObj.date), 'MM/dd'));
                }
            }
        }
        const hasSurcharge = dateObj.data && Number(totalSurcharge) !== 0;
        const isFlexDate = !!dateObj?.data?.flexDetails;
        let flexSideRailMessage = null;
        if (isFlexDate) {
            const unparsedMessage = dateObj?.data?.flexDetails?.option?.[0]?.flexMsg?.find((message) => message.msgType === '6')?.msgText;
            if (unparsedMessage) flexSideRailMessage = ReactHTMLParser(unparsedMessage);
        }

        const surchargeMessage =   hasSurcharge
            ? () => {
                this.mouseOverHandler(serviceCharge, dateToFocus);
            }
            : () => (this.mouseOverHandler(standardCharge, dateToFocus));
        return (
            <span
                key={day}
                role="presentation"
                name="7"
                // the mouseOverHandler keeps track of both whether the user is hovering over any date
                // as well as whether the date they are hovering over has a surcharge.
                // if there is a surcharge we change what text we display on the calendar right banner
                // when the user is hovering over that date
                onMouseLeave={
                    hasSurcharge
                        ? () => {
                            this.mouseOverHandler(standardCharge);
                        }
                        : () => (this.mouseOverHandler(standardCharge))
                }
                onMouseEnter={
                    flexSideRailMessage
                        ? () => {
                            this.mouseOverHandler(flexSideRailMessage, dateToFocus);
                        }
                        :  surchargeMessage
                }
                onClick={() => {
                    if (isClickableUnavailableDate) {
                        this.handleUnavailableClickAbleDatePress(fakeDateInfo);
                        return;
                    }
                    if (this.isNewDeliveryCalendarEnabled()) {
                        this.handleSelectedDateOnClick(dateObj);
                        trackEvent({
                            eventCategory: 'Test Impression',
                            eventAction: 'Calendar Date Clicked',
                            eventLabel: `Clicked-${format(dateObj.date, 'yyyy-MM-dd')}`,
                        });
                        if ((dateObj && !dateObj?.data?.rangeStartDate) || (exactDateOnly && dateObj && dateObj?.data?.rangeStartDate)) {
                            this.handleExactButtonOnClick(formattedDate);
                        }
                        if (!exactDateOnly && dateObj && dateObj?.data?.rangeStartDate) {
                            this.setState({ formattedSelectedDate: formattedDate });
                            this.handleRangeButtonOnClick(dateObj);
                        }
                    } else {
                        this.selectDate(dateObj);
                    }
                }}
                className={hasSurcharge
                    ? `${this.isNewDeliveryCalendarEnabled() ? classes.dateAvailableV2 : classes.dateAvailable} ${this.isNewDeliveryCalendarEnabled() ? classes.dateSurchargeV2 : classes.dateSurcharge} ${calendarCellStyling}`
                    : `${this.isNewDeliveryCalendarEnabled() ? classes.dateAvailableV2 : classes.dateAvailable} ${calendarCellStyling}`}
                // This is where the styling determined by the above functions is applied
                style={this.isNewDeliveryCalendarEnabled() ? styleBtn : dateStyle}
            >
                <span key={day} className={classes.notVisible}>
                    {/* if the date that the user is currently hovering over has a surcharge then display that surcharge in the cell */}
                    {/* otherwise continue to display the date */}
                    {!isHoverDisableOnDesktop && dateIsFocused && Number(totalSurcharge) !== 0 ? this.determineSurchargeDisplay(Number(totalSurcharge)) : day}
                    {dateHasIcon && !isPriceSheetValueIncluded  ? <img className={`${classes.dateIcon} ${dateIsFocused && !isHoverDisableOnDesktop ? `${classes.dateIcon}-hiddenOnHover` : ''}`} src={dateIconData?.iconData?.url} alt={dateIconData?.iconData?.name} /> : null}
                </span>
                <DesktopMobile
                    mobile={() => (!isSurchargeDisableOnMobile || this.isNewDeliveryCalendarEnabled()) && renderSurchargeOnDate()}
                    desktop={() => (isMobileStyleSurchargeEnabledOnDesktop || this.isNewDeliveryCalendarEnabled()) && renderSurchargeOnDate()}
                />
            </span>
        );
    };

    renderMonthTitles = (monthName, year, checkCurrentMonth = false, deliveryDatesInNextMonth, startDayOfWeek, calendar = []) => {
        const {
            classes, brandId, deviceType,
        } = this.props;
        const { prevBtnCheck } = this.state;
        return (
            <MonthTitles
                brandId={brandId}
                classes={classes}
                monthName={monthName}
                year={year}
                startDayOfWeek={startDayOfWeek}
                nextMonth={this.nextMonth}
                preMonth={this.preMonth}
                checkCurrentMonth={checkCurrentMonth}
                prevBtnCheck={prevBtnCheck}
                deliveryDatesInNextMonth={deliveryDatesInNextMonth}
                isFinalDateDisplayed={this.isFinalDateDisplayed}
                calendar={calendar}
                ccoCalendarFlag={this.isNewDeliveryCalendarEnabled()}
                deviceType={deviceType}
            />
        );
    };

    closeAndResetState = () => {
        const { close } = this.props;
        initialExactDate = '';
        initialRangeDate = '';
        close();
        this.setState({
            prevBtnCheck: false,
            surcharge: 'Standard Service Charge',
            now: '',
            end: '',
            spinnerActive: false,
            dateObj: {},
            exactButtonClicked: false,
            rangeButtonClicked: false,
        });
    };

    getHolidayBanner = () => {
        const { cmsData } = this.props;
        const flexBanner = cmsData?.entries?.[0]?.flex_holiday_banner;
        if (flexBanner && flexBanner?.holiday_date_range?.start_date && flexBanner?.holiday_date_range?.end_date) {
            return flexBanner;
        }
        return null;
    }

    handleRangeButtonOnClick = (dateObj) => {
        const { exactButtonClicked } = this.state;
        initialExactDate = '';
        if (exactButtonClicked) {
            this.setState({
                rangeButtonClicked: true,
                exactButtonClicked: false,
            });
        }
        if (dateObj && dateObj?.data?.rangeStartDate) {
            this.setState({
                rangeButtonClicked: true,
                exactButtonClicked: false,
                exactDateOnly: false,
            });
        }
        if (dateObj && dateObj?.rangeStartDate) {
            this.setState({
                rangeButtonClicked: true,
                exactButtonClicked: false,
            });
        }
    }

    exactDateOnly = () => {
        this.setState({
            exactDateOnly: true,
        });
    }

    handleExactButtonOnClick = (formattedDate) => {
        initialRangeDate = '';
        this.setState({
            exactButtonClicked: true,
            rangeButtonClicked: false,
            formattedSelectedDate: formattedDate,
        });
    }

    handleCalendarButtonClick = (formattedDate) => {
        initialRangeDate = '';
        initialExactDate = '';
        this.setState({
            exactButtonClicked: false,
            rangeButtonClicked: false,
            formattedSelectedDate: formattedDate,
        });
    }

    selectFirstAvailableExactDate = (currentMonthArray, now) => {
        let result;
        result = currentMonthArray.map((week) => (
            week.find((element) => element?.available)
        ));
        initialExactDate = result.find((element) => element?.available);

        if (initialExactDate?.date < startOfMonth(new Date(now))) {
            result = currentMonthArray[0].filter((week) => week.date >= startOfMonth(new Date(now)));
            initialExactDate = result.find((element) => element?.available);
        }
    }

    selectFirstDateRange = (currentMonthArray, nextMonthArray) => {
        let result;
        result = currentMonthArray.map((week) => (
            week.find((element) => element?.data?.rangeStartDate)
        ));
        initialRangeDate = result.find((element) => element?.data?.rangeStartDate);
        if (initialRangeDate === undefined) {
            result = nextMonthArray.map((days) => (
                days.filter((element) => element?.data?.rangeStartDate >= initialExactDate?.data?.deliveryDate)
            ));
            const nested = result.map((week) => week.find((element) => element?.data?.rangeStartDate));
            initialRangeDate = nested.find((range) => range?.data?.rangeStartDate);
        }
        if (initialRangeDate?.data?.rangeStartDate < initialExactDate?.data?.deliveryDate) {
            result = currentMonthArray.map((days) => (
                days.filter((element) => element?.data?.rangeStartDate >= initialExactDate?.data?.deliveryDate)
            ));
            const nested = result.map((week) => week.find((element) => element?.data?.rangeStartDate));
            initialRangeDate = nested.find((range) => range?.data?.rangeStartDate);
        }
    }

    nextAvailableRange = (currentMonthArray, nextMonthArray) => {
        const { formattedSelectedDate, dateObj } = this.state;
        let result;
        let nested;
        const lastWeekOfCurrentMonthArray = currentMonthArray[currentMonthArray.length - 1];
        const lastDayOfCurrentMonthArry = lastWeekOfCurrentMonthArray[lastWeekOfCurrentMonthArray.length - 1];
        if (dateObj && !dateObj?.data?.rangeStartDate) {
            if (formattedSelectedDate > format(new Date(lastDayOfCurrentMonthArry?.date), 'yyyy-MM-dd')) {
                result = nextMonthArray.map((days) => (
                    days.filter((element) => element?.data?.rangeStartDate >= formattedSelectedDate)
                ));
                nested = result.map((week) => week.find((element) => element?.data?.rangeStartDate));
                this.setState({
                    dateObj: nested.find((range) => range?.data?.rangeStartDate),
                });
            }
            if (formattedSelectedDate <= format(new Date(lastDayOfCurrentMonthArry?.date), 'yyyy-MM-dd')) {
                const selectedDateinLastWeek = lastWeekOfCurrentMonthArray.find((date) => date?.data?.deliveryDate === formattedSelectedDate);
                result = currentMonthArray.map((days) => (
                    days.filter((element) => element?.data?.rangeStartDate >= formattedSelectedDate)
                ));
                nested = result.map((week) => week.find((element) => element?.data?.rangeStartDate));
                this.setState({
                    dateObj: nested.find((range) => range?.data?.rangeStartDate),
                });
                if (selectedDateinLastWeek && selectedDateinLastWeek.data.deliveryDate) {
                    result = currentMonthArray.map((days) => (
                        days.filter((element) => element?.data?.rangeStartDate <= formattedSelectedDate)
                    ));
                    nested = result.map((week) => week.findLast((element) => element?.data?.rangeStartDate));
                    this.setState({
                        dateObj: nested.findLast((range) => range?.data?.rangeStartDate),
                    });
                }
            }
            if (formattedSelectedDate <= format(new Date(lastDayOfCurrentMonthArry?.date), 'yyyy-MM-dd') && currentMonthArray.length <= 2) {
                result = nextMonthArray.map((days) => (
                    days.filter((element) => element?.data?.rangeStartDate >= formattedSelectedDate)
                ));
                nested = result.map((week) => week.find((element) => element?.data?.rangeStartDate));
                this.setState({
                    dateObj: nested.find((range) => range?.data?.rangeStartDate),
                });
            }
        }
    }

    deliveryMessage = (weekendDate, guaranteedCharge, totalSurcharge) => {
        if (weekendDate === 'S' && totalSurcharge === '0.00' && guaranteedCharge !== '0.00' && guaranteedCharge !== 'NaN') {
            return `Standard + $${guaranteedCharge} Weekend Charge`;
        }
        if (weekendDate === 'S' && totalSurcharge !== '0.00') {
            return `Standard + $${totalSurcharge} Weekend Charge`;
        }
        if (totalSurcharge !== '0.00') {
            return `Standard + $${totalSurcharge} Exact Date Charge`;
        }
        return 'Standard';
    }

    render() {
        const {
            flexTaggingData, surcharge, now, end, spinnerActive, flex, altCalendar: { altCalendarActivated, altCalendarData }, clickableUnavailableDates, exactButtonClicked, rangeButtonClicked, dateObj, formattedSelectedDate, guaranteedCharge,
        } = this.state;
        const {
            isOpen, /* close, */ classes, cmsData, todayTomDateNotAvailText, userSubmittedZip, brandId,
            cartError, addToCartError, featureFlags, deliveryType, deviceType, inclementWeatherBanner, trackEvent, ldFlag, location,
        } = this.props;
        const experimentCalendarFlag = ldFlag?.[`experimentCalendarFirstWeedaySunday${deliveryType === 'FPT' ? 'Fpt' : 'Gpt'}`];
        const startDayOfWeek = featureFlags?.[`is-floral-calendar-first-day-of-week-sunday-${deliveryType?.toLowerCase?.()}`] && experimentCalendarFlag?.toLowerCase() !== 'control' ? 0 : 1;
        const flexHolidayBanner = this.getHolidayBanner();
        let ranges = [];
        const isDesktop = deviceType === 'desktop';
        const todayFormattedDate = format(new Date(), 'yyyy-MM-dd');
        const tomorrowFormattedDate = format(addDays(new Date(), 1), 'yyyy-MM-dd');
        let deliveryMessageTitle = '';
        let deliveryChargeMessage = '';
        let arrivalDateString = '';
        if (dateObj?.data?.rangeStartDate) {
            if (dateObj?.data?.rangeStartDate) {
                const rangeStartDateFormatted = format(addDays(new Date(dateObj?.data?.rangeStartDate), 1), 'M/dd');
                const rangeEndDateFormatted = format(addDays(new Date(dateObj?.data?.deliveryDate), 1), 'M/dd');
                deliveryMessageTitle = 'Standard Delivery';
                arrivalDateString = `Arrives Between ${rangeStartDateFormatted} and ${rangeEndDateFormatted}`;
                deliveryChargeMessage = 'Standard Delivery Charge';
            }
        } else if (rangeButtonClicked && initialRangeDate) {
            deliveryMessageTitle = 'Standard Delivery';
            arrivalDateString = `Arrives Between ${format(addDays(new Date(initialRangeDate?.data?.rangeStartDate), 1), 'M/dd')} and ${format(addDays(new Date(initialRangeDate?.data?.deliveryDate), 1), 'M/dd')}`;
            deliveryChargeMessage = 'Standard Delivery Charge';
        } else if (dateObj?.data?.deliveryDate) {
            const dayOfWeek = format(addDays(new Date(dateObj?.data?.deliveryDate), 1), 'EEEE');
            const rangeEndDateFormatted = format(addDays(new Date(dateObj?.data?.deliveryDate), 1), 'M/dd');
            const totalSurcharge = (Number(dateObj?.data?.surcharge || 0) + Number(dateObj?.data?.upcharge || 0)).toFixed(2);
            deliveryMessageTitle = 'Exact Date Delivery';
            arrivalDateString = `Arrives by ${dayOfWeek} - ${rangeEndDateFormatted}`;
            deliveryChargeMessage = totalSurcharge !== '0.00' ? `Standard + $${totalSurcharge} Exact Date Charge` : 'Standard';
        } else {
            deliveryMessageTitle = 'Delivery Type';
            arrivalDateString = 'Select a date';
            deliveryChargeMessage = 'Select a date';
        }

        if (exactButtonClicked) {
            if (dateObj?.data?.deliveryDate) {
                initialExactDate = '';
                const dayOfWeek = format(addDays(new Date(dateObj?.data?.deliveryDate), 1), 'EEEE');
                const weekendDate = format(addDays(new Date(dateObj?.data?.deliveryDate), 1), 'EEEEE');
                const rangeEndDateFormatted = format(addDays(new Date(dateObj?.data?.deliveryDate), 1), 'M/dd');
                const totalSurcharge = (Number(dateObj?.data?.surcharge || 0) + Number(dateObj?.data?.upcharge || 0)).toFixed(2);
                deliveryMessageTitle = 'Exact Date Delivery';
                arrivalDateString = `Arrives by ${dayOfWeek} - ${rangeEndDateFormatted}`;
                deliveryChargeMessage = this.deliveryMessage(weekendDate, guaranteedCharge, totalSurcharge);
            }
            if (initialExactDate?.data?.deliveryDate) {
                const dayOfWeek = format(addDays(new Date(initialExactDate?.data?.deliveryDate), 1), 'EEEE');
                const weekendDate = format(addDays(new Date(initialExactDate?.data?.deliveryDate), 1), 'EEEEE');
                const rangeEndDateFormatted = format(addDays(new Date(initialExactDate?.data?.deliveryDate), 1), 'M/dd');
                const totalSurcharge = (Number(initialExactDate?.data?.surcharge || 0) + Number(initialExactDate?.data?.upcharge || 0)).toFixed(2);
                deliveryMessageTitle = 'Exact Date Delivery';
                arrivalDateString = `Arrives by ${dayOfWeek} - ${rangeEndDateFormatted}`;
                deliveryChargeMessage = weekendDate === 'S' && totalSurcharge === '0.00' ? `Standard + $${guaranteedCharge || totalSurcharge} Weekend Charge` : `Standard + $${totalSurcharge} Exact Date Charge`;
            }
        }
        if (dateObj?.data?.rangeStartDate && exactButtonClicked && dateObj?.data?.surcharge === '0.00' && dateObj?.data?.upcharge === '0.0') {
            if (dateObj?.data?.deliveryDate) {
                const dayOfWeek = format(addDays(new Date(dateObj?.data?.deliveryDate), 1), 'EEEE');
                const rangeEndDateFormatted = format(addDays(new Date(dateObj?.data?.deliveryDate), 1), 'M/dd');
                const totalSurcharge = (Number(dateObj?.data?.surcharge || 0) + Number(dateObj?.data?.upcharge || 0)).toFixed(2);
                deliveryMessageTitle = 'Exact Date Delivery';
                arrivalDateString = `Arrives by ${dayOfWeek} - ${rangeEndDateFormatted}`;
                deliveryChargeMessage = totalSurcharge !== '0.00' ? `Standard + $${guaranteedCharge || totalSurcharge} Exact Date Charge` : 'Standard';
            }
            if (initialExactDate?.data?.deliveryDate) {
                const dayOfWeek = format(addDays(new Date(initialExactDate?.data?.deliveryDate), 1), 'EEEE');
                const rangeEndDateFormatted = format(addDays(new Date(initialExactDate?.data?.deliveryDate), 1), 'M/dd');
                const totalSurcharge = (Number(initialExactDate?.data?.surcharge || 0) + Number(initialExactDate?.data?.upcharge || 0)).toFixed(2);
                deliveryMessageTitle = 'Exact Date Delivery';
                arrivalDateString = `Arrives by ${dayOfWeek} - ${rangeEndDateFormatted}`;
                deliveryChargeMessage = `Standard + $${totalSurcharge} Exact Date Charge`;
            }
        }
        if (exactButtonClicked && formattedSelectedDate === todayFormattedDate) {
            if (dateObj?.data?.deliveryDate) {
                const rangeEndDateFormatted = format(addDays(new Date(dateObj?.data?.deliveryDate), 1), 'M/dd');
                const totalSurcharge = (Number(dateObj?.data?.surcharge || 0) + Number(dateObj?.data?.upcharge || 0)).toFixed(2);
                deliveryMessageTitle = 'Same Day Delivery';
                arrivalDateString = `Arrives Today - ${rangeEndDateFormatted}`;
                deliveryChargeMessage = `Standard + $${totalSurcharge} Same Day Charge`;
            }
            if (initialExactDate?.data?.deliveryDate) {
                deliveryMessageTitle = 'Same Day Delivery';
                arrivalDateString = `Arrives Today - ${format(addDays(new Date(initialExactDate?.data?.deliveryDate), 1), 'M/dd')}`;
                deliveryChargeMessage = `Standard + $${(Number(initialExactDate?.data?.surcharge || 0) + Number(initialExactDate?.data?.upcharge || 0)).toFixed(2)} Same Day Charge`;
            }
        }
        if (exactButtonClicked && formattedSelectedDate === tomorrowFormattedDate) {
            if (dateObj?.data?.deliveryDate) {
                const rangeEndDateFormatted = format(addDays(new Date(dateObj?.data?.deliveryDate), 1), 'M/dd');
                const totalSurcharge = (Number(dateObj?.data?.surcharge || 0) + Number(dateObj?.data?.upcharge || 0)).toFixed(2);
                deliveryMessageTitle = 'Next Day Delivery';
                arrivalDateString = `Arrives Tomorrow - ${rangeEndDateFormatted}`;
                deliveryChargeMessage = `Standard + $${totalSurcharge} Same Day Charge`;
            }
            if (initialExactDate?.data?.deliveryDate) {
                deliveryMessageTitle = 'Next Day Delivery';
                arrivalDateString = `Arrives Tomorrow - ${format(addDays(new Date(initialExactDate?.data?.deliveryDate), 1), 'M/dd')}`;
                deliveryChargeMessage = `Standard + $${(Number(initialExactDate?.data?.surcharge || 0) + Number(initialExactDate?.data?.upcharge || 0)).toFixed(2)} Same Day Charge`;
            }
        }
        let holidayBannerInWeekRange = false;
        if (featureFlags['is-calendar-holiday-banner-enabled'] && flexHolidayBanner?.holiday_date_range?.start_date && flexHolidayBanner?.holiday_date_range?.end_date) {
            const currentWeek = getWeek(new Date(), { weekStartsOn: startDayOfWeek });
            const holidayBannerStartWeek = getWeek(yyyy_mm_ddToDate(flexHolidayBanner?.holiday_date_range?.start_date), { weekStartsOn: startDayOfWeek });
            const holidayBannerEndWeek = getWeek(yyyy_mm_ddToDate(flexHolidayBanner?.holiday_date_range?.end_date), { weekStartsOn: startDayOfWeek });
            if (compareAsc(yyyy_mm_ddToDate(flexHolidayBanner?.holiday_date_range?.end_date), yyyy_mm_ddToDate(formatDateYYYY_MM_DD(new Date()))) >= 0) {
                if (
                    ((holidayBannerStartWeek - currentWeek) <= 5 && (holidayBannerStartWeek - currentWeek) >= 0)
                || ((holidayBannerEndWeek - currentWeek) <= 5 && (holidayBannerEndWeek - currentWeek) >= 0)) {
                    holidayBannerInWeekRange = true;
                }
            }
        }
        const isHolidayBannerEnabled = featureFlags['is-calendar-holiday-banner-enabled'] && holidayBannerInWeekRange;

        if ((featureFlags['is-calendar-inclement-weather-banner-enabled'] || isHolidayBannerEnabled)) {
            if (inclementWeatherBanner && inclementWeatherBanner?.date_range?.start_date && inclementWeatherBanner?.date_range?.end_date) {
                ranges = this.generateDaysByRange(inclementWeatherBanner?.date_range?.start_date, inclementWeatherBanner?.date_range?.end_date);
            } else if (isHolidayBannerEnabled && !inclementWeatherBanner && flexHolidayBanner && flexHolidayBanner?.holiday_date_range?.start_date && flexHolidayBanner?.holiday_date_range?.end_date) {
                ranges = this.generateDaysByRange(flexHolidayBanner?.holiday_date_range?.start_date, flexHolidayBanner?.holiday_date_range?.end_date);
            }
        }

        if (this.isNewDeliveryCalendarEnabled()) {
            if (exactButtonClicked) {
                ranges = [];
                ranges.push(formattedSelectedDate);
            } else if (rangeButtonClicked && dateObj && dateObj?.data?.rangeStartDate) {
                const rangeStartDate = format(addDays(new Date(dateObj?.data?.rangeStartDate), 1), 'yyyy-MM-dd');
                const rangeEndDate = format(addDays(new Date(dateObj?.data?.deliveryDate), 1), 'yyyy-MM-dd');
                ranges = this.generateDaysByRange(rangeStartDate, rangeEndDate);
            } else if (rangeButtonClicked && initialRangeDate?.data?.rangeStartDate) {
                const rangeStartDate = format(addDays(new Date(initialRangeDate?.data?.rangeStartDate), 1), 'yyyy-MM-dd');
                const rangeEndDate = format(addDays(new Date(initialRangeDate?.data?.deliveryDate), 1), 'yyyy-MM-dd');
                ranges = this.generateDaysByRange(rangeStartDate, rangeEndDate);
                initialRangeDate = '';
            }
        }
        if (!cmsData?.entries?.[0]) {
            return null;
        }
        const serviceLevels = cmsData?.entries?.[0]?.service_levels || [];
        const deliveryImage = cmsData?.entries?.[0]?.local_florist_delivery_icon !== null
            ? cmsData?.entries?.[0]?.local_florist_delivery_icon?.url
            : cmsData?.entries?.[0]?.shipping_in_gift_box_icon?.url;
        const clickableUnavailableMessaging = clickableUnavailableDates?.fakeDateInfo?.messaging;
        let unavailableClickAddToCartDateObj;
        if (clickableUnavailableDates.unavailableDateModalActive && typeof clickableUnavailableDates?.fakeDateInfo?.add_to_cart_date !== 'undefined') {
            unavailableClickAddToCartDateObj = this.apiDateObj[clickableUnavailableDates.fakeDateInfo.add_to_cart_date];
        }
        if (!now) {
            return null;
        }

        let shippingMessage = '';
        if (featureFlags['is-calendar-negative-surcharge-message-enabled'] && this.negativeSurchargeDates.length > 0) {
            if (this.negativeSurchargeDates.length === 1) {
                shippingMessage = (
                    <span>
                        Order early on <b> {this.negativeSurchargeDates[0]} </b> & save <b> {this.determineNegativeSurchargeDisplay(Number(this.negativeSurchargeAmount))} </b> on shipping
                    </span>
                );
            } else {
                shippingMessage = (
                    <span>
                        Order early on <b> {this.negativeSurchargeDates[0]} - {this.negativeSurchargeDates[this.negativeSurchargeDates.length - 1]} </b> & save <b> {this.determineNegativeSurchargeDisplay(Number(this.negativeSurchargeAmount))} </b> on shipping
                    </span>
                );
            }
        }

        const calendar = this.generateCalendar(now, end, startDayOfWeek);
        const nextMonth = firstOfNextMonth(now);
        const currentMonthArray = this.generateMonth(
            calendar,
            getWeek(startOfMonth(new Date(now)), { weekStartsOn: startDayOfWeek }),
            getWeek(endOfMonth(new Date(now)), { weekStartsOn: startDayOfWeek }),
        );
        let nextMonthArray = [];
        if (!isSameMonth(now, end)) {
            nextMonthArray = this.generateMonth(
                calendar,
                getWeek(new Date(nextMonth), { weekStartsOn: startDayOfWeek }),
                getWeek(endOfMonth(new Date(nextMonth)), { weekStartsOn: startDayOfWeek }),
            );
        }
        // sets initialRnageDate variable to the first element in CurrentMonthArray that has a rangeStartDate to set
        // the first range of dates available if the user clicks on Range button on initial load of calendar modal
        if (dateObj && !dateObj?.data?.rangeStartDate) {
            this.selectFirstDateRange(currentMonthArray, nextMonthArray);
        }
        // sets initialExactDate variable to the first element in the currentMonthArray that is available which sets
        // the first availabe date if exact button is clicked on initial load of calendar modal
        if (dateObj && !dateObj?.data?.deliveryDate) {
            this.selectFirstAvailableExactDate(currentMonthArray, now);
        }
        // resets these two variables if a date is selected from directly by clicking on calendar date which sets dateObj
        if (dateObj && dateObj.date) {
            initialExactDate = '';
            initialRangeDate = '';
        }
        if (rangeButtonClicked) {
            this.nextAvailableRange(currentMonthArray, nextMonthArray);
        }
        if ((initialRangeDate && initialRangeDate?.data.deliveryDate) || rangeButtonClicked || dateObj === undefined) {
            if (!location.pathname.includes('add-ons')) {
                this.handleRangeButtonOnClick(initialRangeDate);
            }
            rangeDatesAvailable = true;
        }
        if (initialRangeDate === undefined || (dateObj && dateObj?.data?.deliveryType === 'on')) {
            rangeDatesAvailable = false;
        }
        addOnsDateObj = dateObj;
        const RenderCalendar = (
            <>
                {(!addToCartError && !cartError)
                    && (
                        <>
                            <CalendarModalHeader
                                brandId={brandId}
                                classes={classes}
                                closeAndResetState={this.closeAndResetState}
                                ccoCalendarFlag={this.isNewDeliveryCalendarEnabled()}
                            />
                            {this.isNewDeliveryCalendarEnabled() && rangeDatesAvailable && ((initialRangeDate && initialRangeDate?.data.deliveryDate) || ((dateObj && (dateObj?.data?.deliveryDate)) || ((rangeButtonClicked || exactButtonClicked) && !dateObj?.data?.rangeStartDate))) && (
                                <div className={classes.calendarViewButtons}>
                                    <div className={classes.deliveryOptionsText}>
                                        Calendar View:
                                    </div>
                                    <button
                                        type="button"
                                        className={rangeButtonClicked ? classes.rangeButtonEnabled : classes.rangeButtonDisabled}
                                        onClick={() => {
                                            if (dateObj?.data?.rangeStartDate) {
                                                this.handleRangeButtonOnClick(dateObj);
                                            } else {
                                                this.handleRangeButtonOnClick(initialRangeDate);
                                            }
                                        }}
                                    >
                                        Date Range
                                    </button>
                                    <button
                                        type="button"
                                        className={exactButtonClicked ? classes.exactButtonEnabled : classes.exactButtonDisabled}
                                        onClick={() => {
                                            this.exactDateOnly();
                                            if (dateObj?.data?.deliveryDate) {
                                                this.handleExactButtonOnClick(dateObj?.data?.deliveryDate);
                                            } else {
                                                this.handleExactButtonOnClick(initialExactDate?.data?.deliveryDate);
                                            }
                                        }}
                                    >
                                        Exact Date
                                    </button>
                                </div>
                            )}
                            {todayTomDateNotAvailText
                                && (
                                    <div className={`${classes.todayTomDateNotAvailContainer} todayTomDateNotAvailText`}>
                                        <p>
                                            {todayTomDateNotAvailText}
                                        </p>
                                    </div>
                                )}
                            {deviceType !== 'mobile' && deliveryType === 'GPT' && <hr className={classes.lineBreak} />}
                            {this.isNewDeliveryCalendarEnabled() && (
                                <>
                                    <div>
                                        {spinnerActive
                                        && (
                                            <div className={classes.spinnerContainer}>
                                                <CalendarLoadingSpinner spinnerActive={spinnerActive} />
                                            </div>
                                        )}
                                        <div className={classes.newCalendarContainer}>
                                            {this.renderMonthTitles(
                                                MMMM_MONTH_NAMES[now.getMonth()],
                                                getYear(now),
                                                true,
                                                nextMonthArray.length >= 0,
                                                startDayOfWeek,
                                                calendar,
                                            )}
                                            {currentMonthArray.map((week) => (
                                                <>
                                                    <div
                                                        key={formatDateYYYY_MM_DD(week[0].date)}
                                                        className={`${classes.daysRow} daysRow`}
                                                    >
                                                        {week.map((day) => this.renderWeekDay(
                                                            z2fmt(day.date.getDate()),
                                                            day,
                                                            now.getMonth(),
                                                            ranges,
                                                            week,
                                                        ))}
                                                    </div>
                                                </>
                                            ))}
                                            {nextMonthArray.length > 0
                                                && this.renderMonthTitles(
                                                    MMMM_MONTH_NAMES[nextMonth.getMonth()],
                                                    getYear(end),
                                                    false,
                                                    null,
                                                    startDayOfWeek,
                                                )}
                                            {nextMonthArray.map((week) => (
                                                <div
                                                    key={formatDateYYYY_MM_DD(week[0].date)}
                                                    className={classes.daysRow}
                                                    data-date={formatDateYYYY_MM_DD(week[0].date)}
                                                >
                                                    {week.map((day) => this.renderWeekDay(
                                                        z2fmt(day.date.getDate()),
                                                        day,
                                                        now.getMonth() === 11
                                                            ? 0
                                                            : now.getMonth() + 1,
                                                        ranges,
                                                        week,
                                                    ))}
                                                </div>
                                            ))}
                                        </div>
                                        <div className={classes.deliveryMessageContainer}>
                                            <div className={classes.arrivalDetails}>
                                                {deviceType === 'mobile' ? <p className={classes.deliveryMessageTitle}>{deliveryMessageTitle} - <span className={classes.deliveryMessageText}>{arrivalDateString}</span></p> : (
                                                    <>
                                                        <p className={classes.deliveryMessageTitle}>{deliveryMessageTitle}</p>
                                                        <span className={classes.deliveryMessageText}>{arrivalDateString}</span>
                                                    </>
                                                )}
                                            </div>
                                            <div className={classes.deliveryDetails}>
                                                {deviceType === 'mobile' ? <p className={classes.deliveryMessageTitle}>Delivery Charge - <span className={classes.deliveryMessageText}>{deliveryChargeMessage}</span></p> : (
                                                    <>
                                                        <p className={classes.deliveryMessageTitle}>Delivery Charge</p>
                                                        <span className={classes.deliveryMessageText}>{deliveryChargeMessage}</span>
                                                    </>
                                                )}
                                            </div>
                                            {deviceType !== 'mobile' && (
                                                <button
                                                    type="button"
                                                    className={classes.applyDateButton}
                                                    onClick={() => {
                                                        if (dateObj && dateObj?.date) {
                                                            this.selectDate(dateObj);
                                                            trackEvent({
                                                                eventCategory: 'Test Impression',
                                                                eventAction: 'New Calendar Apply Date Button',
                                                                eventLabel: 'Clicked',
                                                            });
                                                        }
                                                        if (exactButtonClicked && initialExactDate && initialExactDate?.data?.deliveryDate) {
                                                            this.selectDate(initialExactDate);
                                                        }
                                                        if (rangeButtonClicked && initialRangeDate && initialRangeDate?.data?.deliveryDate) {
                                                            this.selectDate(initialRangeDate);
                                                        }
                                                    }}
                                                >
                                                    Apply Date
                                                </button>
                                            )}
                                        </div>
                                        {deviceType === 'mobile' && (
                                            <button
                                                type="button"
                                                className={classes.applyDateButton}
                                                onClick={() => {
                                                    if (dateObj && dateObj?.date) {
                                                        this.selectDate(dateObj);
                                                        trackEvent({
                                                            eventCategory: 'Test Impression',
                                                            eventAction: 'New Calendar Apply Date Button',
                                                            eventLabel: 'Clicked',
                                                        });
                                                    }
                                                    if (exactButtonClicked && initialExactDate && initialExactDate?.data?.deliveryDate) {
                                                        this.selectDate(initialExactDate);
                                                    }
                                                    if (rangeButtonClicked && initialRangeDate && initialRangeDate?.data?.deliveryDate) {
                                                        this.selectDate(initialRangeDate);
                                                    }
                                                }}
                                            >
                                                Apply Date
                                            </button>
                                        )}
                                    </div>
                                </>
                            )}
                            {!featureFlags['is-new-delivery-calendar-enabled'] && !this.isNewDeliveryCalendarEnabled() && (
                                <>
                                    <DialogTitle className={`${classes.dialogTitle} ${classes.calendarBoxV2}`}>
                                        {isHolidayBannerEnabled && !inclementWeatherBanner && flexHolidayBanner && (<CalendarBanner deliveryType={deliveryType} data={flexHolidayBanner} featureFlags={featureFlags} />)}
                                        {featureFlags['is-calendar-inclement-weather-banner-enabled'] && inclementWeatherBanner && (<CalendarBanner deliveryType={deliveryType} weatherBanner data={inclementWeatherBanner} />)}
                                        <div className={classes.calendarMobileLayout}>
                                            {this.renderMonthTitles(
                                                MMMM_MONTH_NAMES[now.getMonth()],
                                                getYear(now),
                                                true,
                                                nextMonthArray.length >= 0,
                                                startDayOfWeek,
                                                calendar,
                                            )}
                                            {currentMonthArray.map((week) => (
                                                <div
                                                    key={formatDateYYYY_MM_DD(week[0].date)}
                                                    className={`${classes.daysRow} daysRow`}
                                                >
                                                    {week.map((day) => this.renderWeekDay(
                                                        z2fmt(day.date.getDate()),
                                                        day,
                                                        now.getMonth(),
                                                        ranges,
                                                        week,
                                                    ))}
                                                </div>
                                            ))}
                                            <CalendarLoadingSpinner spinnerActive={spinnerActive} />
                                            {nextMonthArray.length > 0
                                                && this.renderMonthTitles(
                                                    MMMM_MONTH_NAMES[nextMonth.getMonth()],
                                                    getYear(end),
                                                    false,
                                                    null,
                                                    startDayOfWeek,
                                                )}
                                            {nextMonthArray.map((week) => (
                                                <div
                                                    key={formatDateYYYY_MM_DD(week[0].date)}
                                                    className={classes.daysRow}
                                                    data-date={formatDateYYYY_MM_DD(week[0].date)}
                                                >
                                                    {week.map((day) => this.renderWeekDay(
                                                        z2fmt(day.date.getDate()),
                                                        day,
                                                        now.getMonth() === 11
                                                            ? 0
                                                            : now.getMonth() + 1,
                                                        ranges,
                                                        week,
                                                    ))}
                                                </div>
                                            ))}
                                            {deviceType !== 'desktop' && shippingMessage && this.negativeSurchargeAmount && this.negativeSurchargeDates.length > 0
                                            && (
                                                <ShippingMessage classes={classes} shippingMessage={shippingMessage} flags={featureFlags} />
                                            )}
                                        </div>
                                    </DialogTitle>
                                    <div className={classes.dialogTitle}>
                                        <div className={classes.divider} />
                                        <div className={classes.bottomContent}>
                                            <CalendarModalBottomDiv
                                                brandId={brandId}
                                                classes={classes}
                                                isCalendarV2Enabled={this.isCalendarV2Enabled()}
                                                serviceLevels={serviceLevels}
                                                altCalendarActivated={altCalendarActivated}
                                                altCalendarData={altCalendarData}
                                                deliveryImage={deliveryImage}
                                                surcharge={surcharge}
                                                isDesktop={isDesktop}
                                                shippingMessage={(
                                                    <>
                                                        {shippingMessage && this.negativeSurchargeAmount && this.negativeSurchargeDates.length > 0
                                                                && (
                                                                    <ShippingMessage desktop classes={classes} shippingMessage={shippingMessage} flags={featureFlags} />
                                                                )}
                                                    </>
                                                )}
                                            />
                                        </div>
                                        {altCalendarActivated
                                                && (
                                                    <AltCalendarWidget altCalendarData={altCalendarData} trackEvent={trackEvent} />
                                                )}
                                    </div>
                                </>
                            )}
                        </>
                    )}
                {(addToCartError || cartError) && <CalendarErrorDialog addToCartError={addToCartError} cartError={cartError} closeAndResetState={this.closeAndResetState} />}
            </>
        );

        // to avoid date collection after first list
        this.lockNegativeSurcharge = true;
        return (
            <div>
                {clickableUnavailableDates.unavailableDateModalActive
                && unavailableClickAddToCartDateObj
                && (
                    <UnavailableDateModal
                        addToCartDate={unavailableClickAddToCartDateObj}
                        messaging={clickableUnavailableMessaging}
                        isOpen={clickableUnavailableDates.unavailableDateModalActive}
                        selectDate={this.selectDate}
                        onClose={this.handleUnavailableClickAbleDatePress}
                        fakeDateInfo={clickableUnavailableDates?.fakeDateInfo}
                        userSubmittedZip={userSubmittedZip}
                        deliveryType={deliveryType}
                    />
                )}

                {flex?.flexActivated === true && (
                    <FlexibleShippingOptions
                        flexTaggingData={flexTaggingData}
                        cmsData={cmsData}
                        flexData={flex?.flexData}
                        isOpen={flex?.flexActivated}
                        handleFlexClose={this.handleFlexClose}
                        flexAddToCart={this.flexAddToCart}
                        dateFlexImage={flex?.dateFlexImage}
                        closeAndResetState={this.closeAndResetState}
                        selectDate={this.selectDate}
                        dataObject={this.dataObject}
                        classes={classes}
                        shippingMessage={shippingMessage}
                        negativeSurchargeAmount={this.negativeSurchargeAmount}
                        negativeSurchargeDates={this.negativeSurchargeDates}
                        weatherBanner={featureFlags['is-calendar-inclement-weather-banner-enabled'] && inclementWeatherBanner && (<CalendarBanner deliveryType={deliveryType} weatherBanner data={inclementWeatherBanner} />)}
                        deviceType={deviceType}
                        location={location}
                        brandId={brandId}
                        flex={flex}
                    />
                )}

                <CalendarModalTransition
                    classes={classes}
                    addToCartError={addToCartError}
                    cartError={cartError}
                    isOpen={isOpen}
                    spinnerActive={spinnerActive}
                    RenderCalendar={RenderCalendar}
                    flags={featureFlags}
                    deviceType={deviceType}
                    brandId={brandId}
                    isNewDeliveryCalendarEnabled={this.isNewDeliveryCalendarEnabled()}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    locationType: getLocationType(state),
    cartError: getCartError(state),
    addToCartError: getAddToCartError(state),
    validatedZip: getValidatedZipcode(state),
    userSubmittedZip: getUserSubmittedProductFilterZipcode(state),
    loader: getLoaderStatus(state),
    addToCartLoader: getAddToCartLoaderStatus(state),
    isCheckout: isCheckoutPage(),
    passportInCart: getPassportSubscriptionStatus(state),
    isPassportUser: getIsPassportUserLoggedIn(state),
    isUserLoggedIn: getIsAuthenticatedStatus(state),
    pmallSummaryInfo: getPmallSummaryInfo(state),
    subscriptionFrequencyData: getSubscriptionFrequencyData(state),
    activeABTests: getActiveABTests(state),
    presentationFamily: getPresentationFamily(state),
    deviceType: getSSRDeviceType(state),
});

const mapDispatchToProps = (dispatch) => ({
    actionAddToCart: bindActionCreators(addToCartNewCartServices, dispatch),
    actionUpdateOrder: bindActionCreators(updateOrder, dispatch),
    clearCartError: bindActionCreators(errorActions.clearErrors, dispatch),
    callClearAddToCartError: bindActionCreators(clearAddToCartError, dispatch),
    trackEvent: bindActionCreators(tagManagerActions.trackEvent, dispatch),
});

CalendarModal.propTypes = {
    classes: object.isRequired,
    isOpen: bool.isRequired,
    close: func.isRequired,
    locationType: string.isRequired,
    actionAddToCart: func.isRequired,
    brandId: string.isRequired,
    selectedItemId: string,
    activeABTests: object,
    // validatedZip: string.isRequired,
    history: object.isRequired,
    cmsData: object.isRequired,
    userSubmittedZip: string.isRequired,
    setCalendarDataLoadingFlag: func.isRequired,
    handleDeliveryAvailability: func.isRequired,
    location: object.isRequired,
    actionUpdateOrder: func.isRequired,
    orderItemId: string,
    deliveryType: string,
    ageDetails: shape({
        birthDay: string,
        birthMonth: string,
        birthYear: string,
        minAge: string,
    }).isRequired,
    partNumber: string.isRequired,
    todayTomDateNotAvailText: string,
    sympathyValue: string,
    pageType: string,
    clearCartError: func,
    callClearAddToCartError: func,
    cartError: string,
    zipCode: string,
    trackEvent: func.isRequired,
    isInternationalProduct: bool,
    internationalCountry: string,
    featureFlags: object,
    flags: object.isRequired,
    isPassportBundleItem: bool,
    personalization: shape({
        indicator: string,
        lineState: arrayOf(
            shape({
                lineNumber: number.isRequired,
                maxChars: number.isRequired,
            }).isRequired,
        ),
        modalOpen: bool.isRequired,
    }).isRequired,
    movieSelected: shape({
        error: bool,
        skuSelected: shape({
            sku: string.isRequired,
            sku_description: string.isRequired,
            sku_price: number.isRequired,
        }),
    }),
    isPassportBundleProduct: shape({
        prices: shape({
            value: number,
        }),
        passportSku: string,
        enable: bool,
    }),
    selectedSku: shape({
        prices: arrayOf(shape({
            type: string,
            value: number,
        })),
    }).isRequired,
    hasWine: string,
    isFlowersSubscriptionFeatureEnabled: bool.isRequired,
    isFlowersTempV1SubscriptionFeatureEnabled: bool.isRequired,
    subscriptionDetails: shape({
        subscriptionType: string,
        subscriptionDuration: string,
        subscriptionFrequency: string,
    }),
    subscription: shape({
        subscriptionKey: array,
        subscriptionIndefinite: string,
        subscriptionType: array,
        implementProductSubscription: bool,
        viewSubscription: bool,
        isTypeDiscovery: bool,
    }),
    handleMiniCartModalClick: func.isRequired,
    enableMinicart: bool.isRequired,
    passportInCart: bool,
    isPassportUser: bool,
    isUserLoggedIn: bool,
    addToCartError: string,

    /**
     * Props pertaining to the Category Quickview Feature
     * @define {isQuckviewOrigin} flag for calendar to know if its used from quickview or not.
     * @define {quickviewSelectDate} function to return the selected date to the quickview component.
     */
    isQuickviewOrigin: bool,
    quickviewSelectDate: func,
    pmallSummaryInfo: shape({
        customizations: arrayOf(
            shape({
                refnum: string,
                description: string,
                'preview-url': string,
            }),
        ),
    }).isRequired,
    getAccessTokenSafely: func.isRequired,
    subscriptionTypeAttribute: string,
    subscriptionFrequencyData: shape({
        duration: string,
        subscriptionType: string,
    }),
    showWrapup: string,
    auth: object.isRequired,
    presentationFamily: string.isRequired,
    quickViewLocationType: string,
    productSkus: array,

    deviceType: string.isRequired,
    attributeCategoryId: string,
    attributeCategoryPath: string,
    attributeCategoryName: string,
    inclementWeatherBanner: object,
    ldFlag: object,
    displayDeliveryCalendar: bool.isRequired,
    isWrapUpEnabled: bool,
    salesforceResponseWrapUpShowHide: object,
};

CalendarModal.defaultProps = {
    selectedItemId: null,
    orderItemId: '',
    callClearAddToCartError: noop,
    clearCartError: noop,
    deliveryType: '',
    todayTomDateNotAvailText: '',
    pageType: '',
    cartError: '',
    zipCode: null,
    isInternationalProduct: false,
    internationalCountry: '',
    isPassportBundleItem: false,
    movieSelected: null,
    isPassportBundleProduct: {},
    hasWine: 'false',
    subscriptionDetails: {},
    subscription: {},
    passportInCart: false,
    isPassportUser: false,
    isUserLoggedIn: false,
    sympathyValue: null,
    addToCartError: '',
    isQuickviewOrigin: false,
    quickviewSelectDate: noop,
    subscriptionTypeAttribute: null,
    subscriptionFrequencyData: {},
    activeABTests: {},
    showWrapup: 'true',
    quickViewLocationType: 'Residence',
    productSkus: [],
    attributeCategoryId: '',
    attributeCategoryPath: '',
    attributeCategoryName: '',
    inclementWeatherBanner: null,
    ldFlag: {},
    featureFlags: {},
    isWrapUpEnabled: false,
    salesforceResponseWrapUpShowHide: {},
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    withStyles(styles),
    withAuth,
    withLDConsumer(),
)(CalendarModal);
